html {
	height: 100%;
	position: relative;
}

.gubg{
    background: url(assets/images/home/bg-1.jpg) no-repeat;
    background-position: center;
    background-size: cover;
}
.homebg{
    background: url(assets/images/home/home-Banner.jpg) no-repeat;
    background-position: top;
    background-size: cover;        
}

.loginClass
{
    cursor: pointer;
}
body {
	height: 100%;
	position: relative;
	font-family: 'Roboto-Regular';
	overflow-x: hidden;
	background: #fff;
    color:#333;
    padding:0 !important;
}
a {
    transition: 0.3s all ease-in-out;
    color:#333;
    text-decoration: none !important;
}
a:hover{
    color:#EC2029;
}
h1,h2,h3,h4,h5,h6{
    margin:0;
    line-height:inherit;
}

.disabled {
    cursor: not-allowed;
    opacity: .65;
    pointer-events: none;
    border: 1px solid red;
    background: red;
    color: #fff;
  }

  .download a {
    text-decoration: underline !important;
    color: #e66499 !important;
    font-family: 'Roboto-Medium';
    cursor: pointer;
}

body p {
    margin: inherit;
}

.back-img{width:15px}

.backoption {
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
    margin: 0 0 10px 0;
}
.backoption a {
    color: #000;
    font-size: 23px;
}
#pickserviceTab.nav.picktabStyle a.active {
    color: #333;
    background: #F6F4F4;
    border: 1px solid red;
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url('/assets/fonts/Roboto-Regular.woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto-Bold';
    src: url('/assets/fonts/Roboto-Bold.woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
	font-family: 'Roboto-Medium';
	src: url('/assets/fonts/Roboto-Medium.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
    font-family: 'Roboto-Black';
    src: url('/assets/fonts/Roboto-Black.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'NotoSans-Bold';
    src: url('/assets/fonts/NotoSans-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'ITF-Rupee-Regular';
    src: url('/assets/fonts/ITF-Rupee-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Gill-Sans';
    src: url('/assets/fonts/Gill-Sans.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto-Light';
    src: url('/assets/fonts/Roboto-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
.parrywareHome .container {
    width: 100%;
    padding: 0 100px;
    max-width: 100%;
}
.fontrobotolight{
    font-family: 'Roboto-Light';
}
.fontrobotomedium{
    font-family: 'Roboto-Medium';
}
.fontrobotoregular{
    font-family: 'Roboto-Regular';
}
.fontrobotobold{
    font-family: 'Roboto-Bold';
}
.fontrobotoblack{
    font-family: 'Roboto-Black';
}
.fontnotobold{
    font-family: 'NotoSans-Bold';
}
.fontgillsans{
    font-family: 'Gill-Sans';
}
.fontitf{
    font-family: 'ITF-Rupee-Regular';
}
.mb30{
    margin-bottom: 30px;
}
.mb50{
    margin-bottom: 50px;
}
.mb20{
    margin-bottom: 20px;
}
.mb15{
    margin-bottom: 15px;
}
.mr30{
    margin-right: 30px;
}
.clrblack{
    color:#333;
}
.clrwhite{
    color:#fff;
}
.clrgreen{
    color:#1E9408;
}
.clrpink{
    color:#E66499;
}
.clrred{
    color:#C64949;
}
.bggrey{
    background: #F8F8F8;
}
.clrblue{
    color: #13CCFF;
}
.clrgrey{
    color:#D6D6D6;
}
.h1title{
    font-size: 60px;
    line-height: 80px;    
}
.h2title{
    font-size: 60px;
    line-height: 70px;
}
.h2subtitle{
    font-size: 40px;
    line-height: 50px;   
}
.h3title{
    font-size: 40px;
    line-height: 50px;
}
.para{
    font-size: 18px;
    line-height: 28px;
    text-align: justify;
}
.paraxl{
    font-size: 32px;
    line-height: 42px;   
}
.paramedium {
    font-size: 24px;
}
.paralarge{
    font-size: 26px;
    line-height: 36px;  
}
.parasmall{
    font-size: 18px;
    line-height: 30px;
}
.bgs{
    background-size: cover;
    position: absolute;
    width:100%;
    height: 100%;
    top:0;
    left: 0;
    right: 0;
}
.pos-abs{
    position:absolute;
}
.btn.parrybtn  {
    position: relative;
}
.btn.parrybtn  span {
    position: relative;
    vertical-align: middle;
}
.btn.parrybtn:hover  span {
    color:#fff;
}
.btn.parrybtn:before{
    content:'';
    background:#E66499;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    transform: scaleX(0);
    transition: transform .6s cubic-bezier(.075,.82,.165,1);
    transform-origin: center right;
}
.btn.parrybtn.onhoverwhite:before{
    background: #fff; 
}
.btn.parrybtn.onhoverwhite:hover  span {
    color:#E66499 ;
}
.btn.parrybtn:hover:before{
    transform: scaleX(1);
    transform-origin: center left;
}
/*****************************************Contact css********************************/

.homebannerpanel.ContactPanel {
    margin: 0;
}
.homebannerpanel.ContactPanel {
    height: 50vh;
}
.booking_inner.contactprofessional {
    padding-top: 60px;
}

.cfp_row {
    padding: 60px 0 0 0;
}
.cfp_grid.cfp_info {
    padding: 0 60px 0 0 !important;
}
.cfpformInner {
    background: #F8F8F8;
    padding: 25px 45px;
}
.ctform-grid {
    margin: 0 0 25px 0;
}
.cfpformInner p img {
    margin: 0 10px 0 0;
}
.ctformstyle.cttextareastyle {
    height: 80px;
}
.ctformstyle {
    height: 60px;
    border-radius: 6px;
    border: 1px solid #BCBCBC;
    width: 100%;
    outline: none;    
    padding:0 10px;
}

.ctform-grid label {
    font-size: 20px;
    font-family: 'Roboto-Medium';
}
.ctform-grid.ctbtn {
    display: flex;
    justify-content: flex-end;
}
.btn.booknow.ctsubmitbtn {
    width: 315px;
    font-size: 24px;
    height: 80px;
}
.cturl {
    color: #1C3868;
    font-family: 'Roboto-Bold';
    font-size: 24px;
    text-decoration: underline !important;
    margin: 30px 0;
    display: inline-block;
    cursor: pointer;
}
.ord_invoice p {
    padding: 0 40px 0 0;
}
address.para.addrinfo {
    margin: 0 0 40px 0;
    line-height: initial;
}
.nhcontact.chcontact {
    justify-content: flex-start;
    margin: 20px 0 0 0;
    width: 100%;
}
.nhcontact.chcontact a {
    font-size: 20px;
}
.design-charge {
    color: #002fa6;
    font-family: 'Roboto-Bold';
    font-size: 20px;
}
/*****************************************Updated css********************************/
.mlist.warrantylogo {
    position: absolute;
    left: -40px;
    top: 0;
}
.mlist.warrantylogo p {
    left: 58px;
    top: 15px;
}
.banTxtInner.homebannerInner h1 {
    text-transform: inherit;
    text-align: left;
}
.deep-btntheme {
    background: #E66499;
    border: navajowhite;
    color: #fff;
    font-family: 'Roboto-Medium';
    padding: 5px 30px;
    position: absolute;
    right: 20px;
    top: 20px;
    border-radius: 100px;
}
.valueformsec.deepcleaninform .valu-form {
    max-width: 800px;
    margin: 0 auto;
    box-shadow: none !important;
}
.deepcleaninform {
    box-shadow: 0 1px 1px darkgrey;
    padding: 30px 0 !important;
}
.deepcleaninform {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 30px;
}
ul.deeplist.deeplist-inn {
    padding: 0;
    list-style: none;
}
ul.deeplist.deeplist-inn li a {
    color: #1C3868;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
ul.deeplist.deeplist-inn li a img {
    margin: 0 10px 0 0;
}
ul.deeplist.deeplist-inn li {
    box-shadow: 0 2px 2px #ccc;
    border-radius: 100px; 
}
section#process-step-sec.renovation-process h2 {
    font-size: 30px;
    line-height: 40px;
}
section#process-step-sec.renovation-process {
    margin: 0;
    padding: 40px 30px 0 30px;
}
.renovation-process .howitworksCarousel .slick-dots {
    left: auto;
    right: 0;
}
.mlist.warrantylogo img {
    width: 54px;
    top: -15px;
}
.clean-panel h3 img {
    margin: 0 10px 0 0;
    width: 40px;
}
.clean-text {
    padding: 0 0 0 50px;
}
.bundle-text {
    background: #1C2968;
    color: #fff;
    padding: 20px 20px;
    margin: 0 0 20px 0;
    font-family: 'Roboto-Medium';
}
.cl-panel-grid {
    display: flex;
}
.cl-text {
    padding: 0 0 0 20px;
}
.valu-form {
    width: 100%;
    text-align: center;
    background: #fff;
    padding: 20px 100px;
    box-shadow: 0 1px 1px #ccc;
}
.strikethrough {
    text-decoration: line-through;
}
.deepcleaningbg{
    background: url(assets/images/deepcleaning/Deep-Cleaning-banner.jpg) no-repeat;
    height: 50vw;
    background-size: cover;
    background-position: center center; ;
}
.thumbimg {
    max-width: 60%;
    margin: 0 0 20px 0;
}
.deeplist {
    padding: 0 0 0 25px;
    margin: 0 0 25px 0;
}
.deeplistbg {
    background: #1D3768 url(assets/images/deepcleaning/deep-cleaning-new.jpg) no-repeat;
    background-size: contain;
    height: 9vw;
    background-position: right;
    padding-top: 20px;
}
.deeplistbg li {
    color: #fff;
    margin: 0 0px 5px 20px !important;
}
.clean-process {
    padding: 50px 50px !important;
}
.clean-panel:nth-child(odd) {
    background: aliceblue;
}
.cleaning-steps h2 {
    padding: 40px 0;
    margin: 0 !important;
}
.clean-panel {
    background: #fff;
    padding: 30px 30px;
    margin: 0 0 20px 0;
    box-shadow: 0 2px 2px #ccc;
    border-radius: 5px;
    position: relative;
}
.clean-panel li {
    color: #000;
}
.clean-panel p {
    color: #000;
    margin: 0 0 20px 0 !important;
    line-height: 30px;
}
.deeplist li {
    font-size: 18px;
    margin: 0 0 5px 0;
}
.valueformsec {
    padding: 30px 0 0 0;
}
.olcombolist li {
    margin: 0 0 5px 0;
}
.download-btn {
    background: #1C3868;
    font-size: 18px;
    color: #fff !important;
    padding: 10px 30px;
    margin: 6px 0;
    display: inline-block;
}

.bundlelist {
    padding: 0 0 0 30px;
}
.bundlelist li {
    margin: 0 0 10px 0;
}
.bundlelist li p {
    font-family: 'Roboto-Bold';
}
.bundlelist li span {
    display: inline-block;
    margin: 0 10px 0 0;
}
.value-process {
    padding: 40px 0;
    background: #1C3868;
    color: #fff;
    text-align: center;
    margin: 30px 0 0 0;
    font-family: 'Roboto-Bold';
}
#process-step-sec {
    margin: 50px 0 0 0;
    padding: 40px 0 0 0;
}
.processcarousel {
    padding: 0 !important;
}
.process-step-left .slick-list {
    padding: 0;
}
.process-steps {
    width: 25%;
    margin: 0 auto;
    padding: 20px 0;
}
.process-steps p {
    text-align: center;
    border: 1px solid #ccc;
    padding: 10px 20px;
    margin: 0 0 25px 0;
    position: relative;
}
.process-steps p:after {
    content: '';
    background: #ccc;
    width: 1px;
    height: 55%;
    position: absolute;
    left: 50%;
    top: 48px;
}
.process-steps p:last-child:after{
    display: none;
}
.banText.remove-pos {
    position: static;
    padding:20px 0 0 0;
}
.banText.remove-pos .breadcrumb-theme {
    position: relative;
    top: -15px;
    display: flex;
    justify-content: center;
}
.banTxtInner{
    display: flex;
    padding: 0 0 20px 0;
}
.cfp_row.cfprow-new {
    padding: 30px 0 0 0;
}

.breadcrumb-theme {
    list-style: none;
    padding: 0;
    display: flex;
}
.breadcrumb-theme li {
    margin: 0 6px;
}
.breadcrumb-theme li a {
    color: #000;
    font-family: 'Roboto-Bold';
}
.clrblue{
    color:#002fa6 !important;
}
.renovationbg
{
    background: url(assets/images/home/newimages/before-after-renovation.jpg) no-repeat;
    height: 50vw;
    background-size: cover;
    background-position:bottom; 
}
.accstyle.deepcleaningacc {
    padding: 15px 15px;
}
.faqaccordion.deepcleaningacc button:after {
    right: 10px;
    width: 15px;
    background-size: contain !important;
    height: 15px;
}

.combodealbg{
    background: url(assets/images/home/newimages/combo-deals-banner.jpg) no-repeat;
    height: 50vw;
    background-size: cover;
    background-position: center center;  
}
.wedesignbg{
    background: url(assets/images/valueadded/execute-design.jpg) no-repeat;
    height: 50vw;
    background-size: cover;
    background-position: center center;  
}
.amcbg{
    background: url(assets/images/home/newimages/amc-banner.jpg) no-repeat;
    height: 50vw;
    background-size: cover;
    background-position: center center;    
}
.ValueaddedPanel .pick_panel img {
    min-height: 100px;
    max-height: 100px;
}
.ValueaddedPanel .pick_panel p {
    min-height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cust-name {
    width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.brandpopup.parrymodal .modal-content {
    background: #1C2968;
    color: #fff !important;
}

.brandpopup.parrymodal .close{
    color:#fff !important;
}
.brandpopup.parrymodal  h2.popuptitle {
    color: #fff ;
}
.brandpopup.parrymodal .selectbrand p {
    font-size: 20px !important;
}
.brandpopup.parrymodal .confirmbx {
    font-size: 18px;
    color:#fff;
    margin: 0 20px;
}
.brandpopup.parrymodal input {
    border: 1px solid #fff;
}
.brandpopup.parrymodal .selectbrand .confirmbx input:checked:after {
    top: 9px;
    color:#000;
}
.brandpopup.parrymodal .selectbrand {
    margin: 0 0 40px 0;
}
.brandpopup.parrymodal  .form-group.uploadpanel {
    text-align: left;
    margin: 30px 0;
}
.brandpopup.parrymodal .popupInner {
    padding: 20px 40px 60px 40px;
}
.brandpopup.parrymodal .popupInner img {
    max-width: inherit;
}
.brandpopup.parrymodal .form-group.uploadpanel label {
    font-size: 18px;
    margin: 0 0 10px 0;
}
.requestlist {
    display: flex;
}
.form-group.uploadpanel select {
    border: 1px solid #BCBCBC;
    background: #F5F5F5;
    width: 200px;
    padding: 4px 4px;
    margin: 0 10px 0 0;
}
.form-group.uploadpanel label {
    display: block;
}
.dsbx.dsbookingpanel .dsInnerTitle p span {
    font-size: 18px;
}
.dsbx.dsbookingpanel .dsInnerTitle p {
    display: flex;
}
/**Saftey css **/
.safety-sidebar {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9;
    background: #fff;
    width: 18.5vw;
    height: 100%;
    transition: 0.3s all ease-in-out;
}
.sf-info span {
    padding: 0 0 0 15px;
}
.cvlist li p {
    min-height: 50px;
    text-align: center;
}
.safetyoverlay:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.6);
    z-index: 9;
}
[hidden].safety-sidebar {
    right: -100%;
    display: block !important;
}
.cvcontext p {
    text-align: justify;
}
.sf-info {
    padding: 15px 20px;
    display: flex;
    align-items: center;
}
.cvcontext {
    padding: 15px 20px;
}
.cv-title {
    font-size: 28px;
}
p.safetyText {
    background: #EDEDED;
    text-align: center;
    font-family: 'Roboto-Medium';
    font-size: 18px;
    padding: 10px 0;
    margin: 0 0 20px 0;
}
.cvlist {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
}
.cvlist li {
    padding: 0 10px;
}
.cvlist span {
    display: block;
    text-align: center;
    padding: 12px 0 0 0;
}
/**Saftey css ends**/
.pickServices .tab-content {
    margin: 0 !important;
}
.sltlocation option {
    color: #000;
}
.logolist {
    width: 27vw;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.logolist a {
    padding: 0 15px 0;
}
.sltlocation {
    width: 100%;
    border: none;
    padding: 0px 0px;
    background: transparent;
    box-shadow: none;
    outline: none;
    margin: 5px 0 0 0;
    font-family: 'Roboto-Bold';
    color:#fff;
    font-size: 20px;
}
.sltdroppanel.mlist p {
    left: 0;
}
table.invoicetable.parrywareTable {
    border-width: 2px !important;
    border-style: solid !important;
    margin: 30px 0 0px 0;
}
table.invoicetable.parrywareTable td {
    border-width: 2px !important;
    border-style: solid !important;
    
}
.txinvoice {
    border-top: 1px solid #fff;
    margin: 20px 0 0;
}
.Contentpanel.invoicecontent {
    height: 100%;
    padding: 40px 40px;
    border: 1px solid #ccc;
    margin: 40px 0 0;
}
.DashboardInner.DashboardInvoice {
    padding: 0 0 100px 0;
}
.dslogo.dslogopanel {
    margin: 0;
}
.Stlist ul li a {
    color: #8C96A8;
}
.Stlist li.st-active a {
    color: #13284D;
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: none !important;
}
.parrywareTable {
    width: 100%;
    background: #fff;
    color: #000;
    margin: 30px 0;
}
.parrywareTable tr:nth-child(odd) {
    background: #f2f2f2;
}
.parrywareTable thead, .parrywareTable td {
    padding: 12px 15px;
    font-size: 18px;
}
.parrywareTable thead {
    background: #009AD0;
    font-family: 'Roboto-Bold';
    color: #fff;
    text-transform: uppercase;
}
table, th, td {
    border-collapse: collapse;
  }
button#closePromo {
    display: none;
}
.gucontext p {
    text-align: justify;
}
p.disclaimertext {
    text-align: justify;
}
.ongoingprlist {
    min-height: 90px;
}
.ongoingprlist p {
    font-size: 16px;
}
.nhcontact a {
    color: #007bff !important;
}
p.disclaimertext {
    font-size: 15px;
}
.couponcode.mb30.form-group.uploadpanel {
    padding: 0;
}
.selectbrand .confirmbx input:checked:after {
    top: 6px;
}
.total.totalconfirmbk {
    width: 90%;
}
.parrymodal.addrpopup .modal-dialog {
    margin: 20px auto;
}
.addrForm .form-group {
    margin-bottom: 10px;
}
.fTopInner p {
    text-align: justify;
    max-width: 70%;
    margin: 0 auto;
}
.cs_inn.cs_inn_confirm p.priceamt {
    justify-content: flex-end;
}
.dsInnerTitle img {
    width: 30px;
}
.ngb-dp-arrow button {
    margin: 0 !important;
}
.gonextbtn.btn.booknow.cartclear {
    background: transparent;
    color: #000 !important;
    text-decoration: underline !important;
    box-shadow: none;
}
.selectbrand {
    margin: 0 0 10px 0;
    display: flex;
}
.selectbrand p {
    padding: 0 10px 0 0 !important;
    margin: 0;
    font-size: 14px !important;
}
.nav.timelist {
    margin: 0 0 20px 0;
}
.Contentpanel h4 {
    margin: 30px 0;
    background: #fff;
    padding: 6px 10px;
    color: #000;
    font-weight: bold;
    display: inline-block;
    font-size: 18px;
}
.Contentpanel p {
    text-align: justify;
}
.btn.booknow.popbtn.deletebtn {
    background: #ccc;
    color: #000;
}
.deletecart {
    display: block;
    margin: 0 0 0 15px;
    cursor: pointer;
    background: #fff;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}
.btnoptions a{
    margin:0 10px  0 0;
}
.couponcode {
    position: relative;
}
.couponcode span {
    color: #00A7D5;
    text-transform: uppercase;
    font-family: 'Roboto-Medium';
    position: absolute;
    top: 12px;
    right: 20px;
}
.couponcode.form-group.uploadpanel input {
    width: 100%;
    display: block;
    padding: 0 20px;
    background: #fff;
    color: #000;
}
.pickServices p a {
    color: #13CCFF;
}
.topHeader {
    background: url(../src/assets/images/home/Top-header-BG.png)no-repeat;
    background-size: cover;
    padding:20px 0;
    width: 100%;
    z-index: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: 'Roboto-Medium';
    position: absolute;
    height: 50px;
    top:-50px;
}
.topHeader p span {
    text-transform: uppercase;
    font-weight: 700;
    font-family: 'Roboto-Bold';
}
.hminn img {
    max-height: 80px;
    margin: 0 0 15px 0;
}
.total p{
    color:#E66499;
}
.mlistpanel {
    display: flex;
    width: 70%;
    justify-content: flex-end;
}
.contul {
    padding: 0 0 0 15px;
    margin: 20px 0 20px 0;
}
.contul li {
    margin: 0 0 20px 0;
}
.h4title {
    font-size: 20px;
    margin: 0 0 20px 0;
    color: #13CCFF;
}
.Contentpanel {
    padding: 50px 0;
}
.nhcontact a img {
    margin: 0 10px 0 0;
}
.cs_inn:last-child{
    border:none;
}
p.bookinTitle {
    background: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0 !important;
    margin: 0 0 10px 0;
}
.cs_inn p span {
    width: 10px;
    margin: 0 10px 0 0;
}
.gotoproduct .addproductbtn {
    width: 150px;
    height: 50px;
}
.gotoproduct .addproductbtn span {
    font-size: 24px !important;
}
.gotoproduct {
    display: flex;
    align-items: center;
}
.dropdown-menu .dropdown-item a {
    font-size: 20px !important;
}
.dropdown-menu .dropdown-item span {
    padding: 0 10px 0 0;
    display: inline-block;
    width: 50px;
}
.dropdown-menu .dropdown-item a {
    font-family: 'Roboto-Regular';
}
.mlist.dropdown {
    display: flex;
}
#dropdownManual {
    color: #fff !important;
    font-size: 20px !important;
    font-family: 'Roboto-Bold';
    border: none;
    padding: 0;
    background: transparent;
    outline: none;
}
.confirmimg {
    max-width: 50px;
}
.dsInnerTitle p {
    align-items: center;
}
.dsInnerTitle p span {
    display: block;
    font-size: 13px;
}
.btn.booknow:hover {
    background: #E66499;
    color: #fff !important;
}
.dsleftpanel p a {
    display: flex;
    align-items: center;
}
.verticalTabContent .tab-pane .card-header {
    display: none;
}
.cartItemDetails .addproductbtn span {
    font-size: 16px;
}
.mt-2.tab-content {
    margin: 0 !important;
}
.nav-tabs.dsproductTabStyle{
    border-bottom:none;
}
.slick-dots li button:before {
 display: none !important;
}
.ServiceSlickCarousel.slick-slider {
    margin: 0 !important;
}
.TabhomeStyle li {
    display: inherit;
}
.invalid-feedback {
    bottom: 0;
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    top: -25px;
}
/*********************************************Banner*********************************/
.homebannerpanel {
    height: 100vh;
    margin: 50px 0 0 0;
}
.banText {
    height: 100%;
    position: absolute;
    padding: 14% 0 80px 0;
}
.valueformsec h3 {
    text-align: center;
}
.banTxtInner h1 {
    max-width: 100%;
    line-height: 65px;
    position: relative;
    z-index: 1;
    text-align: center;
    width: 100%;
    padding: 20px 0 0;
    text-transform: uppercase;
}
.blueoverlay:before {
    content: '';
    background: #00A7D5;
    display: block;
    height: 55px;
    position: absolute;
    top: 30px;
    left: -12px;
    width: 680px;
    z-index: -1;
}
.nav.picktabStyle {
    border: none;
}
#pickserviceTab.nav.picktabStyle a.active {
    color: #333;
    background: #F6F4F4;
}
.nav.picktabStyle a {
    border: none;
    background:#496086;
    color: #FFFFFF;
    font-size: 20px;
    margin: 0 10px 0 0;
    width: 200px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    border-radius: 10px 10px 0 0;
}
.servicesrow {
    background: #F6F4F4;
    padding: 25px 5px 25px 25px;
    border-radius: 0 10px 10px 10px;
}
.servicegrid {
    padding:0 10px !important;
}
.pick_panel {
    background: #fff;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0 3px 5px #00000029;
}
.pick_panel p {
    padding: 15px 5px;
    text-align: center;
    font-family: 'NotoSans-Bold';
    color:#000;
}
/***************************************Header************************************/
.HeaderPanel {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 1;
}
.logo {
    width: 20vw;
}
.logo a img{
    width: 14vw;
}
.menuPanel {
    width: 70vw;
    justify-content: flex-end;
    display: flex;
    padding: 15px 0 0 0;
    position: relative;
}
.HeaderInner {
    display: flex;
    padding: 20px 0 0 0;
}
.mlist p {
    font-size: 16px;
    color: #dee2e6;
    font-family: 'Roboto-Bold';
    padding: 0 0 5px 0px;
    text-transform: uppercase;
    position: relative;
    left: 28px;
    text-align: left;
}
.mlist a {
    color: #fff !important;
    font-size: 20px !important;
    font-family: 'Roboto-Bold';
    cursor: pointer;
}
.mlist span {
    vertical-align: text-top;
}
.booknow .hr_booknow{
    width:150px;
    height: 60px;
    border-radius: 5px;
    background: #FFFFFF;
    color:#E66499;
    font-family: 'Roboto-Bold';
    font-size: 20px;
    box-shadow: 0 3px 6px #0000003B;
    text-transform: uppercase;
    margin:0 0 0 70px;
    border: none;
}
.headermenuline {
   /* background: #79716E;*/
    background: #ccc;
    width: 2px;
    height: 70px;
    display: block;
    margin: 0 30px;
}
.mlist.dropdown .dropdown-toggle:after {
    display: none;
}
.mlist.dropdown .dropdown-menu {
    background: #00A7D5;
    border: none;
    border-radius: 0;
    min-width: 230px;
    box-shadow: 0 3px 6px #00000029;
    margin: 0;
    padding: 0;
    top: 10px !important;
}
.dropdown-menu .dropdown-item {
    font-size: 20px;
    font-family: 'Roboto-Regular';
    padding: 22px 30px;
    border-bottom: 1px dashed #17AFD9;
    display: flex;
    align-items: center;
    background: transparent !important;
    color: #fff !important;
}
.dropdown-menu a:hover{
    background: transparent !important;
}
.dropdown-menu a span {
    padding: 0;
    width: 50px;
    display: inline-block;
}
.mlist.dropdown .dropdown-menu:before {
    content: '';
    position: absolute;
    top: -15px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #00A7D5;
    left: 47px;
}
.mlist a img {
    position: relative;
    top: -5px;
    margin: 0 5px 0 0;
}
/*****************************************Footer************************************/
.fTopInner {
    padding: 60px 0 60px 0;
}
.copyright p {
    padding: 20px 0;
}
.copyright:before {
    content: '';
    background: #fff;
    width: 50%;
    height: 1px;
    display: block;
    margin: 0 auto;
}
.social-grid p {
    color: #fff;
    font-size: 18px;
    font-family: 'Roboto-Medium';
}
.social-menu {
    display: flex;
    padding: 0;
    list-style: none;
    margin: 0 0 0 10px;
}
.social-menu li a {
    width: 30px;
    display: inline-block;
    height: 30px;
    margin: 0 10px 0 0;
}
.fbtmRow {
    padding: 40px 0;
}
.line {
    background: #333;
    width: 2px;
    height: 20px;
    display: block;
    margin: 0 40px;
}
.nav.menu_footer {
    align-items: center;
}
.menu_footer li a {
    font-size: 20px;
    color:#fff;
}
.social-menu {
    display: flex;
    padding: 0;
    list-style: none;
    margin: 0 0 0 10px;
}
.social-menu li a {
    width: 30px;
    display: inline-block;
    height: 30px;
    margin: 0 10px 0 0;
}
.social-grid {
    display: flex;
    justify-content: flex-end;
}
.footerBtm {
    background: #1C3868; 
}
/****************************************Home*********************************/
.booking_inner {
    padding-top: 20px;
}
.bkgrid {
    text-align: center;
    margin: 0 0 40px 0;
}
.bookingPanel {
    background: #F8F8F8;
}
.txtbx {
    border: 1px solid #BBBBBB;
    width: 100%;
    height: 80px;
    border-radius: 10px;
    padding: 0 25px;
    outline: none;
    font-size: 22px;
}
.booking_inner .bkformgrid {
    padding: 0 8% 0 0;
}
.submitbtn {
    background: #E66499 !important;
    width: 100px;
    color: #FFFFFF!important;
    font-family: 'NotoSans-Bold';
    font-size: 12px;
    text-transform: uppercase;
    padding: 10px 0;
    position: absolute;
    right: 25px;
    top: 20px;
    box-shadow: 0 3px 6px #e0e0e0;
    outline: none;
    border-radius: 5px;
}
.access a {
    display: inline-block;
    margin: 0 30px 0 0;
}
.booking_inner .bkformgrid {
    padding: 5% 8% 0 0;
}
.form-booking{
    max-width: 660px;   
}
/*********************************FAQ******************************/
.faqpanel {
    background: #1C3868;
    padding:0 0 100px 0;
}
.faqInner {
    padding: 75px 0 0 0;
}
.faqInner .TabhomeStyle {
    justify-content: center;
    border: none;
    margin: 0 0 70px 0;
}
#faqTab.TabhomeStyle a {
    color: #C2C2C2;
    font-size: 30px;
    background: transparent !important;
    border: none;
    padding: 0;
}
#faqTab.TabhomeStyle a.active {
    color: #13CCFF;
}
.tabline {
    display: block;
    margin: 0 70px;
    background: #fff;
    width: 2px;
    height: 30px;
    margin-top: 10px;
}
#faq-tabContent {
    margin-bottom: -470px;
    position: relative;
}
.faqaccordion {
    background: #1C2968;
    padding:70px 130px 50px 130px;
    display: block;
}
.faqaccordion .card {
    background: transparent;
    border-bottom: 1px solid #646464 !important;
    border: none;
}
.faqaccordion .card-header {
    background: transparent;
    border: none;
    padding: 0;
}
.faqaccordion button {
    background: transparent;
    display: block;
    text-align: left;
    padding: 0;
    color: #fff !important;
    font-size: 26px;
    font-family: 'Roboto-Bold';
    outline: none;
    outline: none !important;
    box-shadow: none !important;
    padding: 20px 0;
    text-decoration: none !important;
    width: 100%;
}
/*.faqaccordion .card-header a {
    background: transparent;
    display: block;
    text-align: left;
    padding: 0;
    color: #fff;
    font-size: 26px;
    font-family: 'Roboto-Bold';
    outline: none;
    outline: none !important;
    box-shadow: none !important;
    padding: 25px 0;
}*/
/*.faqaccordion .card-header a:after {
    content: '';
    position: absolute;
    right: 30px;
    background: url(/assets/images/home/plus.svg) no-repeat;
    width: 26px;
    background-size: contain;
    height: 26px;
}
.faqaccordion .card-header  a[aria-expanded="true"]:after{
    background: url(/assets/images/home/minus.png) no-repeat;
}*/
.faqaccordion button:after {
    content: '';
    position: absolute;
    right: 30px;
    background: url(/assets/images/home/plus.svg) no-repeat;
    width: 26px;
    background-size: contain;
    height: 26px;
}
.faqaccordion button[aria-expanded="true"]:after{
    background: url(/assets/images/home/minus.png) no-repeat;
}
.faqaccordion .card-body {
    color: #D8D8D8;
    font-size: 26px;
    font-family: 'Roboto-Light';
    padding: 0 0 30px 0;
}
.faqaccordion .card:last-child {
    border: none !important;
}
/****************************GUarantee panel**********/
.gupanel {
    height: 49vw;
}
.bgs {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
}
.gucontext {
    display: flex;
    align-items: center;
    height: 100%;
    max-width: 65vw;
}
/************************SErvices Panel*******/
.ServicesPanel {
    background: #2E3B7B;
}
.ServicesInner {
    padding: 60px 0 60px 0;
}
.slickcarouelstyle.slick-slider {
    margin: 0;
    padding: 30px 0 0px 0;
}
.slickcarouelstyle .slick-list {
    margin: 0 -12px;
}
.slickcarouelstyle .slick-slide {
    outline: none;
    padding: 0 12px;
}
.blogimg img {
    width: 100%;
}
.blogcont {
    background: #00A7D5;
    color: #fff;
    min-height: 165px;
    padding: 30px 15px;
    border-radius: 0 0 10px 10px;
    margin: -10px 0 0;
    z-index: 1;
    position: relative;
}
.blogcont p {
    font-size: 22px;
    margin: 0 0 20px 0;
    min-height: 75px;
}
.blogcont span {
    font-size: 28px;
    font-family: 'Roboto-Bold';
    width: 70%;
}
.ServiceSlickCarousel .slick-list {
    overflow: visible;
}
.ServiceSlickCarousel  .slick-dots {
    position: static;
    margin: 70px 0 0 0;
}
.slickcarouelstyle .slick-dots li.slick-active {
    width: 23px;
    background: #00A7D5;
}
.slickcarouelstyle .slick-dots li {
    width: 13px;
    height: 13px;
    border: 1px solid #707070;
    background: #fff;
    border-radius: 100px;
}
.slickcarouelstyle .slick-arrow {
    width: 30px;
    background-size: 100% 100% !important;
    height: 50px;
    top: -75px;
    right: 0;
    left: auto;
}
.slickcarouelstyle .slick-prev.slick-arrow {
    background: url(/assets/images/home/arrow-left.png) no-repeat;
    right: 80px;
}
.slickcarouelstyle .slick-next.slick-arrow {
    background: url(/assets/images/home/arrow-right.png) no-repeat;
}
.slickcarouelstyle .slick-arrow:before {
    display:none;
}
.slickcarouelstyle .slick-arrow.slick-disabled {
    opacity: 0.5;
}
.homelistpanel {
    background: #1C2968;
    text-align: center;
}
.hmrow {
    padding: 50px 0;
}
/******************************how it works***************/
.hwpanel {
    background: #1C3868;
}
.hwrow {
    padding: 50px 120px 70px 140px;
}
.hrowtitle {
    position: relative;
    top: 80px;
}
.hwleft {
    padding: 60px 0 0;
}
.hwright {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}
.howitworksCarousel>div {
    padding: 0 0 30px 0;
}
.btn.booknow {
    width: 225px;
    height: 70px;
    background: #E66499;
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
    font-family: 'Roboto-Bold';
    border-radius: 5px;
    box-shadow: 0 3px 6px #0000003b;
}
.slickcarouelstyle .slick-slide {
    outline: none !important;
}
.howitworksCarousel .slick-list {
    overflow: visible;
}
.howitworksCarousel .slick-slide {
    float: none;
    margin: 0;
    cursor: pointer;
}
.howitworksCarousel .slick-slide p {
    display: none;
}
.howitworksCarousel .slick-slide.slick-active h2, .howitworksCarousel .slick-slide.slick-active p {
    opacity: 1;
    transition: 0.5s all ease-in-out;
}
.howitworksCarousel .slick-slide.slick-active p {
   display: block;
}
.howitworksCarousel .slick-slide.slick-active p {
    display: block;
    margin: 0 0 30px 0;
}
.howitworksCarousel h2 span {
    padding: 0 50px 0 0;
}
.howitworksCarousel .slick-slide h2, .howitworksCarousel .slick-slide p {
    opacity: 0.2;
    transition: 0.5s all ease-in-out;
}
.howitworksCarousel .slick-dots li.slick-active {
    width: 13px;
    height: 23px;
}
.howitworksCarousel .slick-dots li {
    margin: 0 0 10px 0;
}
.howitworksCarousel .slick-dots {
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: auto;
    left: -15%;
    top: auto;
    bottom: 20%;
}

.pickServices {
    margin: 70px 0 0 0;
    width:57vw;
    position: absolute;
    bottom: 90px;
}
.servicesrow .col-auto {
    flex: 0 0 20%;
    max-width: 20%;
}

/****************************login popup***********/
.parrymodal   .modal-dialog {
    width: 40%;
    max-width: 40%;
    text-align: center;
    margin: 100px auto;
}
.parrymodal   .modal-content {
    border: none;
    border-radius: 0px;
}
.parrymodal   .modal-header {
    padding: 10px 20px;
    border: none;
}
.parrymodal   .close {
    color: #A8A8A8;
    opacity: 1;
    font-size: 65px;
    padding: 0;
    font-weight: normal;
    font-family: 'Roboto-Light';
    margin: 0 0 auto auto;
    height: auto;
    outline: none;
}
.parrymodal   .modal-body {
    padding: 0;
}
.popuptitle {
    font-size: 30px;
}
.popupsubtitle {
    font-size: 24px;
}
.popupInner {
    padding: 20px 100px 60px 100px;
}
.btn.fullwidth{
    width:100%;
}
.pop_txtbx {
    background: #F5F5F5;
}
.otpformgroup {
    padding: 0 70px;
}
.otpformInner{
    display: flex;
}
.otpformgroup .otptxt {
    margin: 0 30px 0 0;
    text-align: center;
}
.otpformgroup .otptxt:last-child {
    margin: 0;
}
.otppanel {
    padding: 30px 0 0 0;
}
.timerstyle {
    color: #51AE00;
    font-size: 22px;
    display: block;
    padding: 15px 0 40px 0;
}
/***********************************Dashboard**************************/
.innercontainer{
    padding: 0 135px;
}
.Stlist {
    margin: 0 auto;
    text-align: center;
    color: #fff;
    max-width: 100%;
    padding: 30px 0 40px 0;
    display: flex;
    justify-content: center;
}
.stline {
    width: 150px;
    display: block;
    border: 0.5px dashed #fff;
    margin: 0 12px;
}
.Stlist ul li {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    font-family: 'Roboto-Bold';
    font-size: 28px;
    color: #8C96A8;
    border: 1px solid #fff;
    background: #13284D;
    position: relative;
}
.Stlist ul li span {
    font-size: 15px;
    color: #fff;
    font-family: 'Roboto-Regular';
    position: absolute;
    bottom: -35px;
    display: block;
    min-width: 160px;
    left: -55px;
}
.Stlist ul li.st-active {
    background: #fff;
    color: #13284D;
}
.Stlist .nav {
    align-items: center;
    position: relative;
}
.DashboardInner {
    background: #1C3868;
}
.dsleftpanel img {
    width: 20px;
}
.dsRow {
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid #344B74;
}
.dsleftpanel p span {
    vertical-align: middle;
    padding: 0 0 0 30px;
    color: #fff;
}
.dsheaderlist {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.dslogo {
    margin:0 0 0 85px;
}
.dslogo a {
    display: block;
    width: 100px;
}
.verticalTabstyle.nav-tabs {
    display: none;
  }
 .ds-products {
    padding: 30px 0 120px 0;
} 
.dsproductTabStyle {
    margin: 0 -5px;
    border: none;
}
.dsproductTabStyle li {
    padding: 0 5px;
    width: 20%;
    margin: 0 !important;
}
.dsproductTabStyle li a {
    display: flex;
    color: #fff;
    background: #00A7D5;
    border-radius: 5px;
    color: #333333;
    font-family: 'Roboto-Black';
    font-size: 20px;
    align-items: center;
    border: none !important;
    padding: 12px 15px !important;
    transition: none;
}
.dsproductTabStyle li .nav-link.active {
    margin-bottom: 0;
    color: #000;
    border-radius: 5px 5px 0 0;
    border-bottom: 5px solid #fff !important;
}
.dsproductTabStyle li a span {
    padding: 0 0 0 30px;
    color:#fff;
}
.dsproductTabStyle li a.active span {
    color:#000;
}
.dsproductTabStyle li a.active span {
    opacity: 1;
}
.ds-products .dsproductCard {
    border: none;
    border-radius: 0;
}
.verticalNav {
    width: 27%;
    border-right: 1px solid #ddd;
}
.dsproductCard.card .verticalTabstyle {
    margin: 0;
    padding: 70px 40px;
    position:sticky;
    top:0;
}
.ds-products .dsproductCard .card-body {
    padding: 0;
}
.verticalTabContent {
    width: 70%;
    padding: 0 40px 40px 40px;
}
.nav.verticalTabstyle li {
    margin: 0 0 20px 0 !important;
}
.nav.verticalTabstyle li a {
    border: 1px solid #00A7D5;
    border-radius: 5px;
    box-shadow: 0 3px 6px #00000029;
    background: #F8F8F8;
    font-size: 20px;
    padding: 10px 20px;
    color:#000;
}
.productpanel {
    position: relative;
    margin: 0 0 30px 0;
    box-shadow: 0 3px 6px #00000030;
    border-radius: 5px 5px;
}
.bestseller {
    position: absolute;
    top: 0;
    left: 0;
    background: #00A7D5;
    color: #ffff;
    font-size: 14px;
    font-family: 'Roboto-Bold';
    width: 108px;
    height: 34px;
    border-radius: 5px 0 00;
    display: flex;
    justify-content: center;
    align-items: center;
}
.details {
    background: #F8F8F8;
    border-top: 1px solid #DEDEDE;
    padding: 20px 20px 20px 35px;
}
.details p {
    margin: 0 0 10px 0;
    position: relative;
}
.details p:before {
    content: '';
    background: #00BCDC;
    width: 6px;
    height: 6px;
    display: block;
    border-radius: 100%;
    position: absolute;
    top: 6px;
    left: -15px;
}
.viewlink {
    color: #00BCDC;
    text-decoration: underline !important;
    font-family: 'Roboto-Bold';
    cursor: pointer;
}
.prCont {
    background: #fff;
}
.productlist {
    margin: 60px 0 0px 0;
}
.productlist p {
    margin: 0 0 15px 0;
    min-height: 65px;
    font-family: 'Roboto-Bold';
    text-align: left;
}
.details p {
    margin: 0 0 10px 0;
    min-height: inherit;  
}
.prdesc span {
    font-size: 14px;
    font-family: 'Roboto-Light';
}
.prdesc {
    padding: 10px 10px 10px 15px;
}
.add_product span {
    font-size: 26px;
    font-family: 'Roboto-Medium';
}
.cartItemDetails{
    float: right;
}
.addproductbtn {
    background: #E66499;
    border: none;
    color: #fff;
    font-size: 18px;
    font-family: 'Roboto-Bold';
    cursor: pointer;
    width: 110px;
    height: 40px;
    align-items: center;
    border-radius: 5px;
    text-transform: uppercase;
    padding: 0 10px;
    outline: none !important;
}
.slitems {
    position: fixed;
    background: #E66499;
    bottom: 110px;
    right: 50px;
    width: 100%;
    max-width: 32%;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px 10px;
    z-index: 1;
}
.slitemslist {
    display: flex;
    align-items: center;
    width: 50%;
}
.slitemslist span {
    border: 1px solid #fff;
    width: 60px;
    text-align: center;
    height: 60px;
    color: #fff;
    font-size: 24px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto-Bold';
}
.slitemslist p {
    color: #fff;
    font-size: 30px;
    padding: 0 0 0 15px;
}
.continute {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
}
.continuelink {
    font-size: 25px;
    color: #fff !important;
    text-transform: uppercase;
    font-family: 'Roboto-Bold';
}
.continuelink img {
    width: 16px;
}
.continuelink img {
    width: 15px;
    vertical-align: text-bottom;
    margin: 0 0 0 20px;
}
.addproductbtn img {
    vertical-align: text-bottom;
    margin: 0 0 0 25px;
    text-align: right;
}
.primg {
    position: relative;
}
.descModal .modal-dialog {
    max-width: 615px;
    border: none;
    border-radius: 0;
    margin: 100px auto 50px auto;
}
.descModal .modal-content {
    background: transparent;
    border: none;
    box-shadow: inherit;
    border-radius: 0px;
}
.descModal .modal-header {
    background: transparent;
    border: none;
    padding: 0;
}
.descModal .modal-body {
    background: #fff;
    padding: 0;
}
.descModal .mdTitle {
    padding: 20px 35px;
    display: flex;
    align-items: center;
}
.mdTitle .prdesc {
    padding: 0;
    width: 50%;
}
.mdTitle .prdesc span {
    font-size: 16px;
    padding: 0 0 5px 0px;
    display: block;
}
.mdTitle .prdesc p {
    font-size: 24px;
}
.mdlist {
    background: #F8F8F8;
    padding: 25px 15px 25px 35px;
}
.mdlist p {
    padding: 0 0 20px 0;
}
.mdcontxt {
    padding: 0 0 0 20px;
    margin: 10px 0 00;
}
.mdcontxt li {
    margin: 0 0 20px 0;
    list-style: none;
    position: relative;
}
.mdcontxt li:before {
    content: '';
    background: #00A7D5;
    width: 6px;
    height: 6px;
    display: block;
    border-radius: 100%;
    position: absolute;
    left: -20px;
    top: 7px;
}
.descModal .close {
    padding: 0 !important;
    color: #fff!important;
    border: 1px solid #fff;
    opacity: 1!important;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    position: absolute;
    top: -20px;
    right: 10px;
    font-family: 'Roboto-Light';
    font-weight: normal;
    outline: none !important;
}
.descModal .close span {
    vertical-align: super;
    display: inline-block;
}
.dsbx {
    background: #F9F9F9;
}
.dsInnerpanel {
    padding: 25px 0 100px 0;
}
.dsbx {
    background: #F9F9F9;
    padding: 40px 35px 50px 35px;
}
.dsInnerTitle {
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dsInnerTitle a {
    position: absolute;
    left: 0;
    font-size: 16px;
    font-family: 'Roboto-Bold';
    text-transform: uppercase;
    display: none;
}
.add_list_panel {
    padding: 0px 0 30px 0;
    max-width: 55%;
    margin: 0 auto;

}
.add_list_panel.addonPanel{
    max-width: 80%;   
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.addonlist {
    margin: 0 0 10px 0;
    padding: 0 10px;
    width: 50%;
}
.add_li {
    border: 2px solid #EEEEEE;
    padding: 10px;
    background: #fff;
    cursor: pointer;
}
.add_li img {
    width: 130px;
}
.addgrid p {
    padding: 0 0 0 25px;
    font-size: 16px;
    color: #4E4E4E;
    font-family: 'Roboto-Medium';
}
.addgrid span {
    display: block;
    margin: 0 25px 0 0;
    font-family: 'Roboto-Bold';
    font-size: 24px;
}
.parry_checkbox {
    -webkit-appearance: none;
    width: 35px;
    height: 35px;
    background: #ddd;
    border: 1px solid #E9E9E9;
    border-radius: 5px;
    outline: none;
    position: relative;
    cursor: pointer;
}
.parry_checkbox:checked{
    background: #00A7D5 !important;
}
.parry_checkbox:checked:after {
    content: '✔';
    position: absolute;
    top: 2px;
    left: 8px;
    font-size: 20px;
    line-height: inherit;
    color: #fff;
    transition: all .2s;
    font-family: 'Roboto-Regular';
}
.addonpattern {
    width: 130px;
    height: auto;
    border-radius: 5px;
    background: #270808;
    min-width: 130px;
}
.gonextpanel {
    max-width: 700px;
    margin: 0px auto auto auto;
}
.blueborder{
    border-color:#00A7D5;
}
.selectedli  .add_li{
    border-color: #00A7D5;
}
.gonextbtn img {
    margin: 0 0 0 10px !important;
    max-width: 10px;
    vertical-align: middle;
}
.btn.newaddressbtn {
    background: #fff;
    border: 2px solid #00A7D5;
    padding: 40px 0;
    margin: 40px 0 50px 0;
    font-size: 22px;
}
.newaddressbtn img {
    margin: 0 25px 0 0;
}
.selected_addr {
    border: 1px dashed #C9C9C9;
    background: #E9E9E9;
    border-radius: 5px;
    position: relative;
    margin: 20px 0 0 0;
}
.selected_addr p {
    font-size: 15px;
}
.selected_addr span {
    position: absolute;
    right: 15px;
    font-size: 45px;
    top: -10px;
}
.selected_addr label {
    margin: 0 0 0 0;
    font-size: 22px;
    padding: 15px 15px;
    cursor: pointer;
}
.selected_addr .parry_checkbox {
    border: 1px solid #707070;
    background: #fff;
    margin: 0 20px 15px 0;
}
.selected_addr label .hme {
    font-size: 22px;
    position: absolute;
    top: 18px;
    left: 60px;
}
.booknow.fontrobotoregular{
    font-family: 'Roboto-Regular';  
}
.btn.greybtn{
    background: #E9E9E9;
    color: #000;
}
.chooselocation {
    margin: 100px 0 100px 0;
}
.addonlist label {
    width: 100%;
}
.dltitle {
    display: flex;
    justify-content: center;
}
.dltitle img {
    margin: 0 20px  0 0;
}
.dateList {
    justify-content: center;
}
.dateList li {
    width: 130px;
    height: 130px;
    border: 1px solid #707070;
    margin:  0 22px;
    color: #333333;
    font-size: 18px;
    text-align: center;
    border-radius: 10px;
    padding: 15px 0 0 0;
    cursor: pointer;
    position: relative;
}
.dateList li span {
    display: block;
    font-size: 50px;
    font-family: 'Roboto-Bold';
}
.dateList li.activedl {
    background: #00A7D5;
    color: #fff;
    border: 1px solid #fff;
}
.dateList li.disabledl {
    opacity: 0.6;
    pointer-events: none;
}
.sltime_panel {
    background: #F3EFEF;
    padding: 35px 0 100px 0;
}
.timelist {
    justify-content: center;
}
.timelist li {
    border: 1px solid #707070;
    border-radius: 10px;
    width: 180px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F9F9F9;
    margin:0 25px 25px 25px;
    font-size: 18px;
    color: #333;
    position: relative;
    cursor: pointer;
}
.timelist li.activetime {
    background: #00A7D5;
    color: #Fff;
    border: 1px solid transparent;
}
.timelist li.disabledtime {
    opacity: 1;
    pointer-events: none;
    border: 1px solid #ff0000;
    background: #ff0000;
    color: #fff;
}

.parrymodal.addrpopup .close {
    position: absolute;
    right: 10px;
    top: 0;
    z-index:1;
}
.parrymodal.addrpopup .modal-dialog {
    max-width:45%;
    width: 45%;
}
.parrymodal.addrpopup .modal-header {
    padding:0;
}
.addrForm {
    text-align: left;
    padding: 50px 45px;
}
.addrForm .form-group p {
    margin: 0 0 20px 0;
}
.addrForm .form-group p {
    margin: 0 0 12px 0;
}
.addrinput.addrtxtarea {
    resize: none;
    height: 150px;
}
.addrinput {
    border: 1px solid #BCBCBC;
    width: 100%;
    background: #F5F5F5;
    height: 60px;
    border-radius: 10px;
    outline: none;
    padding: 10px 10px;
}
.save-as-panel {
    background: #F1F1F1;
    padding: 25px 35px;
    text-align: left;
}
.save_url {
    display: flex;
    margin: 10px 0 30px 0;
}
.save_url a {
    border: 1px solid #707070;
    width: 125px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px 0 0;
    border-radius: 10px;
    background: #F9F9F9;
    font-size: 18px;
    text-transform: uppercase;
    color:#333;
}
.save_url a.activeSave {
    background: #00A7D5;
    color: #fff;
    border: 1px solid transparent;
    font-family: 'Roboto-Bold';
}
.addrimg img {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
.dsbx.dsbookingpanel {
    padding: 0;
}
.dsbx.dsbookingpanel {
    padding: 0;
}
.dsbx.dsbookingpanel .dsInnerTitle {
    padding:25px 30px;
}
.dsbx.dsbookingpanel .dsInnerTitle a {
    left: 40px;
}
.bookingrow .bookinggrid.bkleft {
    background: #F3F1F1;
    padding: 35px 60px 15px 35px;
}
.bookinggrid p {
    padding: 0 0 20px 0;
}
.bk-details {
    padding: 20px 0 0 0;
}
.confirmbx span a {
    padding: 0 0 0 10px;
    text-decoration: underline !important;
    cursor: pointer;
}
.bkwarranty {
    background: #E9E9E9;
    padding: 15px 15px 0 15px;
}
.confirmbx span {
    padding: 0px 0 0 10px;
    display: flex;
}
.uploadpanel {
    margin: 15px 0;
}
.form-group.uploadpanel {
    padding: 0 50px 0 0;
}
.form-group.uploadpanel input {
    border: 1px solid #BCBCBC;
    color: #000;
    width: 45%;
    height: 45px;
    border-radius: 5px;
    background: #F5F5F5;
    outline: none;
    padding: 5px 15px 5px 15px;
    margin:10px 15px 0px 0;
}
.form-group.uploadpanel button {
    background: #1C3868;
    color: #fff;
    border: none;
    color: #fff;
    border: none;
    margin: 0;
    width: 45%;
    font-size: 13px;
}
.bkwarranty p {
    font-size: 12px;
}
.dsbookingpanel .service-check a {
    color: #00A7D5;
    text-decoration: underline !important;
    margin: 0 0 10px 0;
    display: inline-block;
}
.nhpanel {
    padding: 20px 0 20px 0;
    display: flex;
    align-items: center;
}
.nhpanel p {
    padding: 0;
}
.nhcontact {
    display: flex;
    justify-content: flex-end;
    width: 80%;
}
.nhcontact a {
    margin: 0 25px 0 0;
    font-size: 18px;
    font-family: 'Roboto-Bold';
    color:#000;
}
.costrow {
    border-bottom: 1px solid #D5D4D4;
    padding: 10px 0 0px 0;
}
.cs_inn {
    font-size: 18px;
    display: flex;
    border-bottom: 1px solid #D5D4D4;
    margin: 0 0 10px 0;
    padding: 10px 0 0;
}
.cs_inn p {
    width: 85%;
    padding:0;
}
.cs_inn p.priceamt {
    display: flex;
    width: 20%;
    justify-content: flex-end;
}
.total {
    padding: 10px 0 0px 0;
}
.bookingrow .bookinggrid.bkpayment {
    background: #E8E8E8;
    padding:35px 60px 15px 35px;
}
.borderbtm {
    border-bottom: 1px dashed #D5D4D4;
}
.confirmbx {
    font-size: 16px;
    color: #333;
    display: flex;
    align-items: center;
    margin: 0;
    position: relative;
}
.confirmbx input {
    width: 18px;
    height: 18px;
    border: 1px solid #707070;
    -webkit-appearance: none;
    border-radius: 2px;
}
.confirmbx input:checked {
background: #fff;
}
.confirmbx input:checked:after {
    content: '✔';
    position: absolute;
    top: 1px;
    left: 2px;
    font-size: 16px;
    line-height: inherit;
    color: #108008;
    transition: all .2s;
    font-family: 'Roboto-Regular';
}
.gonextpanel.nomargin {
    margin: 0;
}
.dsInnerTitle img {
    margin: 0 10px 0 0;
    vertical-align: middle;
}
.border-top-bottom {
    border-top: 1px dashed #D5D4D4;
    border-bottom: 1px dashed #D5D4D4;
}
.service-check a img {
    margin: 0 0 0 10px;
    width: 10px;
}
.service-check {
    padding: 15px 0 0;
}
.btnrequestpanel {
    padding: 40px 0;
}
.btn.btnrequest {
    width: 215px;
    height: 60px;
    padding: 0;
    background: #fff;
    border-radius: 5px;
    font-size: 18px;
    text-transform: uppercase;
    margin: 0 30px 0 0;
    font-family: 'Roboto-Bold';
    border: 1px solid #333;
}
.btn.btnrequest.rescedulebtn {
    border: 1px solid #E66499;
    color: #E66499;
}
.btn.btnrequest.cancelrequest {
    color: #00A7D5;
    border: 1px solid #00A7D5;
}
.innerbg{
    background: #F9F9F9;
}
.orderpanel {
    max-width:40%;
    margin: 0 auto;
    text-align: center;
    padding: 45px 0;
}
.orderpanel img {
    max-width: 180px;
}
.ServiceCompleted {
    background: #00AB40;
}
.ratingdesc {
    display: flex;
    align-items: flex-start;
    margin: 0 50px 0 0;
}
.ratinginfor {
    padding: 0 0 0 20px;
}
.ratinginfor p {
    padding: 0;
}
.gonextpanel a {
    display: flex;
    align-items: center;
    justify-content: center;
}
a.gonextbtn.btn {
    display: flex;
    align-items: center;
    justify-content: center;
}
.pick_panel img {
    width: 100%;
}
.servicegridCarousel.slickcarouelstyle {
    padding: 20px 20px;
    min-height: 250px;
}
.servicegridCarousel  .slick-list{
    padding: 20px 20px;
}
.ratingblue img {
    width: 22px;
}
.ratingblue {
    font-size: 18px;
    font-family: 'Roboto-Bold';
    color: #009BCF;
    display: flex;
    align-items: center;
}
.ratingblue img {
    width: 22px;
    margin: 0 6px;
}
.ratepopup .modal-header {
    padding: 0;
}
.ratepopup button.close {
    position: absolute;
    right: 15px;
    top: 0;
    font-size: 50px;
    z-index: 1;
}
.ratepopup .modal-body {
    padding: 0 90px;
}
.rateinnerpanel {
    padding: 35px 0;
    border-bottom: 1px solid #D9D8D8;
}
.rateinnerpanel span {
    color: #AFAFAF;
    font-size: 20px;
    font-family: 'Roboto-Bold';
    display: inline-block;
    padding: 10px 0 00;
}
.ratepanel p {
    width: 40%;
    text-align: left;
}
.ratepanel {
    display: flex;
    padding: 30px 0 0;
}
.rateformpanel {
    border-top: 1px solid #D9D8D8;
    padding: 15px 0 75px 0;
    margin: 35px 0 0 0;
    text-align: left;
}
.rateformpanel .form-group {
    margin: 0 0 30px 0;
}
.rateformpanel .form-group label {
    display: block;
    font-size: 20px;
    font-family: 'Roboto-Bold';
    padding: 0 0 20px 0;
}
.ratepanel span {
    font-size: 30px;
    color: #00BCDC;
    font-family: 'Roboto-Bold';
    /* position: absolute; */
    right: 100px;
}
.nav.bookingTabstyle a {
    background: #ccc;
    color: #333333;
    font-family: 'Roboto-Black';
}
.bookingTab .tab-content {
    background: #fff;
    padding: 40px 40px 200px 40px;
}
.bkdesc {
    padding: 20px 10px 30px 10px;
    text-align: left;
}
.view-info {
    background: #F7F7F7;
    padding: 30px 10px 10px 10px;
    text-align: left;
    min-height: 190px;
}
.orderrow {
    box-shadow: 0 3px 6px #00000030;
}
.bookingTab {
    padding: 50px 0 120px 0;
}
.view-info .booknow {
    font-size: 16px;
}
.bkdesc {
    padding: 20px 10px 10px 10px;
    text-align: left;
    min-height: 100px;
}
.orderrow {
    box-shadow: 0 3px 6px #00000030;
}
.info-details {
    font-size: 14px;
    padding: 0 0 20px 0;
}
.view-info .booknow {
    font-size: 16px;
    height: 60px;
    width: 100%;
}
.bktabrow {
    padding: 10px 0 0 0;
}
.requestbx img {
    margin: 0 10px 0 0;
    width: 50px;
}
.requestbx {
    display: flex;
    align-items: center;
    padding: 0px 0 30px 0;
}
.bkdate {
    font-size: 14px;
    margin: 5px 0 00;
}
.viewdetbx {
    max-height: 80px;
    min-height: 80px;
}
.bkdate span {
    padding: 0 0 0 3px;
}
.btn.vieworderbtn {
    background: #fff;
    color: #E66499;
    border: 1px solid #E66499;
}
.history-para {
    font-size: 14px;
    color: #959595;
}
.view-info.viewhistory {
    min-height: 190px;
}
.orderrow.requestcancel {
    box-shadow: 0 3px 6px #00000030;
}
.orderrow.requestcancel .bkdesc {
    background: #F7F7F7;
}
.orderrow.requestcancel .view-info {
    background: #f3f3f3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}
.bookingTab .tab-content.noactiveorders {
    padding: 40px 40px 150px 40px;
}
.orderpanel p.paraxl {
    font-size: 26px;
}
.booking_inner .bkgrid{
    margin:0;
}
.dateList li.activedl:before {
    content: '';
    background: url(/assets/images/dashboard/bluetick.png) no-repeat;
    width: 30px;
    height: 30px;
    display: block;
    position: absolute;
    bottom: -15px;
    background-size: contain;
    left: 50px;
    right: 0;
}
.timelist li.activetime:before{
    content: '';
    background: url(/assets/images/dashboard/bluetick.png) no-repeat;
    width: 30px;
    height: 30px;
    display: block;
    position: absolute;
    bottom: -15px;
    background-size: contain;
    left: 75px;
    right: 0; 
}
.guimg {
    width: 210px;
    margin: 125px 0 50px 0;
}
.slick-dots li button{
    display: none;
}
.headerTop {
    display: none;
}
.dsgrid.dsleftpanel p {
    display: flex;
    align-items: center;
}
.servicearea {
    position: absolute;
    background: #fff;
    width: 75%;
    margin: 25px 0 0 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s all ease-in-out;
    text-align: center;
}
.servicearea.showservicearea {
    visibility: visible;
    opacity: 1;
}
.servicearea p {
    color: #000;
    padding: 15px 15px;
    position: static;
}
.instarow {
    background: #fff;
    padding: 40px 55px 65px 40px;
    border-radius: 10px;
}
.instarow .instaleft {
    padding: 0 50px 0 0;
}
.instaright p span {
    font-family: 'Roboto-Bold';
    font-size: 40px;
    vertical-align: middle;
    padding: 0 0 0 10px;
}
.instaright p {
    font-family: 'Roboto-Light';
    font-size: 15px;
    padding: 0 0 5px 0;
}
.instagrid.instaright {
    text-align: right;
}
.pickServices .tab-content>.tab-pane {
    display: block;
    height: 0;
    overflow: hidden;
}
.pickServices .tab-content>.tab-pane.active {
    height: auto;
}
.ss-blog{
    transition: 0.3s all ease-in-out;
    transform: scale(1);
}
.ss-blog:hover{
    transform: scale(1.03);
}
.TabStyle-products {
    padding: 0px 60px 60px 60px;
}
.add_remove_panel {
    color: #fff;
    border-radius: 5px;
    
}
.add_remove_panel span {
    display: flex;
    text-align: center;
    background: #E66499;
    flex: 0 0 33%;
    max-width: 33.33%;
    float: left;
    justify-content: center;
    font-size: 26px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    cursor: pointer;
    padding: 0 12px;
}
.showitems .add_remove_panel{
    display:block;
}
.showitems .addproductbtn{
    display: none;
}
.add_remove_panel  .qtyText{
    background: #fff;
    color: #000;
    border-top: 1px solid #E66499;
    border-bottom: 1px solid #E66499;
    cursor:inherit;
}
/*******************************************Responsive**********************************************/
@media only screen and (min-width: 2000px)  {

.headermenuline {
    margin: 0 2vw;
}
.pick_panel img {
    width: 100%;
}
.pick_panel p {
    font-size: 20px;
}
.mlist a img {
    width: 1.2vw;
}
.booknow .hr_booknow {
    width: 8.3vw;
    height: 2.8vw;
    font-size: 1vw;
    margin: 0 0 0 3vw;
}
.banText {
    width: 100%;
}

.pickServices {
    width: 57vw;
}
}
@media only screen and (min-width: 1600px)  and (max-width:1800px){
.nav.verticalTabstyle li a {
    font-size: 16px;
    padding: 10px 15px;
}
.banText {
    padding: 11% 0 80px 0;
}
.brandpopup.parrymodal .modal-dialog {
    width: 50%;
    max-width: 50%;
}
.cvlist span {
    font-size: 12px;
}
.cvcontext {
    padding: 15px 15px;
}
.sltlocation {
    font-size: 18px;
}
.headermenuline {
    height: 60px;
}
.booknow .hr_booknow {
    height: 55px;
    margin: 0 0 0 60px;
}
.add_list_panel.addonPanel{
    max-width: 90%;
}
.ongoingprlist p {
    font-size: 14px;
}
.parry_checkbox:checked:after { 
    left: 6px;
    font-size: 18px;
}
.parry_checkbox {
    width: 30px;
    height: 30px;
}
.paramedium {
    font-size: 20px;
}
p.disclaimertext {
    font-size: 13px !important;
}
.bookingrow .bookinggrid.bkleft p {
    font-size: 20px;
}
.parrymodal.addrpopup .modal-dialog {
    margin: 20px auto;
}
.paraxl {
    font-size: 24px;
    line-height: 34px;
}
.gotoproduct .addproductbtn {
    width: 120px;
    height: 40px;
}
.parrymodal .close {
    font-size: 50px;
}
.addrForm {
    padding: 50px 45px 30px 50px;
}
.btn.booknow {
    height: 65px;
    font-size: 18px;
}
.info-details {
    font-size: 13px;
}
.orderpanel p.paraxl {
    font-size: 22px;
}
.ratepanel span {
    right: 60px;
}
.dateList li {
    width: 110px;
    height: 110px;
    margin: 0 20px;
    font-size: 15px;
}
.mb50 {
    margin-bottom: 40px;
}
.dateList li span {
    font-size: 45px;
}
.selected_addr p {
    font-size: 14px;
}

.addgrid p {
    font-size: 14px;
}
.descModal .modal-dialog {
    margin: 80px auto 35px auto;
}
.mdcontxt li {
    margin: 0 0 15px 0;
    font-size: 15px;
}
.dsproductTabStyle li a {
    font-size: 18px;
}
.para {
    font-size: 15px;
    line-height: 25px;
}
.dsproductTabStyle li a span {
    padding: 0 0 0 20px;
}

.parrywareHome .container {
    padding: 0 80px;
}
.h1title {
    font-size: 60px;
}
.headermenuline {
    margin: 0 25px;
}

.mlist p {
    font-size: 13px;
}
.mlist a {
    font-size: 18px !important;
}
.logo a img {
    width: 240px;
}
.paralarge {
    font-size: 24px;
}
.nav.picktabStyle a {
    font-size: 17px;
    width: 180px;
    height: 60px;
}
.hwrow {
    padding: 50px 40px 70px 140px;
}
.h2subtitle {
    font-size: 40px;
    line-height: 50px;
}
.blogcont p {
    font-size: 20px;
}
.parasmall {
    font-size: 18px;
    line-height: 28px;
}
.fTopInner {
    padding: 60px 0 60px 0;
}
.h3title {
    font-size: 28px;
    line-height: 46px;
}
.menu_footer li a {
    font-size: 18px;
    color:#fff;
}
.line {
    margin: 0 30px;
}
}
@media only screen and (min-width: 1500px) and (max-width: 1600px) {
.pickServices {
    bottom: 60px !important;
}
.mlist.warrantylogo {
    left: 65px;
}
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
.ValueaddedPanel .pick_panel img {
    min-height: 85px;
    max-height: 85px;
}
section#process-step-sec.renovation-process h2 {
    font-size: 24px;
    line-height: 32px;
}

.banTxtInner h1 {
    line-height: 55px;
}
.servicegridCarousel.slickcarouelstyle {
    min-height: 190px;
}
.ValueaddedPanel .pick_panel p {
    min-height: inherit;
}
.parrywareHome .container {
    padding: 0 50px;
}
.confirmbx {
    font-size: 14px;
}
.cust-name {
    width: 125px;
}

.brandpopup.parrymodal .modal-dialog {
    width: 55%;
    max-width: 55%;
    margin:30px auto;
}
.brandpopup.parrymodal .selectbrand .confirmbx input:checked:after {
    top: 10px;
    color: #000;
}
.cvlist li p {
    min-height: 50px;
}
.sf-info img {
    width: 10px;
}
.safety-sidebar p {
    font-size: 12px;
    line-height: 22px;
}
.cvlist span {
    font-size: 11px;
}
.cvcontext {
    padding: 15px 15px;
}
.sf-info.para {
    padding: 12px 15px;
    text-align: left;
    font-size: 13px;
}
.cv-title {
    font-size: 20px;
}
.sltlocation {
    font-size: 16px;
}
.form-group.uploadpanel {
    padding: 0 20px 0 0;
}
.add_remove_panel span {
    font-size: 22px;
}
.btn.btnrequest {
    height: 50px;
    font-size: 16px;
}
.parrywareTable thead, .parrywareTable td {
    font-size: 16px;
}
.ongoingprlist p {
    font-size: 14px;
    line-height: inherit;
    margin:0 0 6px 0;
}
.add_list_panel.addonPanel {
    max-width: 87%;
}
.selectbrand .confirmbx input:checked:after {
    top: 3px;
}
.dsbx.dsbookingpanel .dsInnerTitle {
    padding: 20px 30px;
}
.parrymodal.addrpopup .modal-dialog {
    margin: 20px auto;
}
.addrForm .form-group p {
    font-size: 13px;
    margin: 0 0 8px 0;
}
.faqaccordion button:after {
    right: 30px;
    width: 20px;
    height: 20px;
}
.Stlist ul li span {
    font-size: 14px;
}
.Contentpanel h4 {
    font-size: 15px;
}
.addrinput.addrtxtarea {
    height: 130px;
}
.addrForm {
    padding: 45px 45px 10px 45px;
}
.parrymodal .close {
    font-size: 50px;
}
.add_list_panel {
    max-width: 50%;
}
.txtbx {
    height: 60px;
}
.addonpattern {
    width: 100px;
    height: auto;
    min-width: 100px;
}
.viewlink {
    font-size: 14px;
}
.details p {
    margin: 0;
}
.btn.booknow.ctsubmitbtn {
    width: 210px;
    font-size: 20px;
    height: 60px;
}
.cturl{
    font-size:20px;
}
.ctformstyle {
    height: 50px;
}
.topHeader p {
    font-size: 14px;
}
.ctformstyle.cttextareastyle {
    height: 70px;
}
.ctform-grid label {
    font-size: 16px;
}
.nhcontact.chcontact a {
    font-size: 16px;
}
.topHeader {
    height: 50px;
    top: -50px;
}
#dropdownManual {
    font-size: 16px !important;
}
.mlist.dropdown .dropdown-menu {
    min-width: 210px;
}
.dropdown-menu .dropdown-item a {
    font-size: 16px !important;
}
.dropdown-menu .dropdown-item {
    font-size: 16px;
    padding: 15px 20px;
}
.dropdown-menu .dropdown-item span {
    width: 35px;
}
.dropdown-toggle img {
    width: 18px;
}
.h4title {
    font-size: 18px;
}
.gotoproduct .addproductbtn {
    width: 100px;
    height: 35px;
}
.gotoproduct .addproductbtn span {
    font-size: 20px !important;
}
.instarow {
    padding: 30px 55px 30px 40px;
}
.dateList li.activedl:before {
    left: 35px;
    width: 25px;
    height: 25px;
}
.history-para {
    font-size: 12px;
}
.bkdate {
    font-size: 12px;
}
.view-info .btn.booknow {
    height: 50px;
    font-size: 14px;
}
.info-details {
    font-size: 12px;
}
.orderpanel p.paraxl {
    font-size: 20px;
}
.ratepanel span {
    right: 40px;
}
.ratepanel span {
    font-size: 26px;
}
.parrymodal.ratepopup .modal-dialog {
    max-width: 45%;
    width: 45%;
}
.ratepopup .modal-body {
    padding: 0 60px;
}
.rateformpanel .form-group label {
    font-size: 16px;
    padding: 0 0 10px 0;
}
.paramedium {
    font-size: 18px !important;
}
.cs_inn p {
    font-size: 14px;
}
.dsbx {
    padding:30px 35px 40px 35px;
}
.timelist li {
    font-size: 16px;
    height: 45px;
}
.timelist li.activetime:before {
    width: 22px;
    height: 22px;

    left: 80px;
}
.dateList li {
    width: 90px;
    height: 90px;
    margin: 0 15px;
    font-size: 12px;
}
.dateList li span {
    font-size: 36px;
}
.addrinput {
    height: 45px;
}
.addrForm {
    padding: 40px 45px 25px 45px;
}
.gonextpanel {
    margin: 0 auto auto auto;
    max-width: 540px;
}
.save_url a {
    width: 125px;
    height: 45px;
    font-size: 16px;
}
.btn.newaddressbtn {
    padding: 25px 0;
    margin: 25px 0 35px 0;
    font-size: 22px;
}
.selected_addr p {
    font-size: 13px;
}
.parry_checkbox {
    width: 25px;
    height: 25px;
}
.parry_checkbox:checked:after {
    top: 0px;
    left: 5px;
    font-size: 16px;
}
.addgrid span {
    font-size: 18px;
    margin: 0 15px 0 0;
}

.addgrid p {
    padding: 0 0 0 12px;
    font-size: 13px;
}
.innercontainer {
    padding:0 30px;
}
.descModal .modal-dialog {
    margin: 60px auto 50px auto;
}
.descModal .close {
    width: 25px;
    height: 25px;
    font-size: 20px;
}
.mdTitle .prdesc p {
    font-size: 20px;
}
.mdTitle .prdesc span {
    font-size: 14px;
}
.mdcontxt li {
    margin: 0 0 12px 0;
    font-size: 13px;
}
.mdlist p {
    padding: 0 0 15px 0;
}
.stline {
    width: 110px;
}
.productRow {
    margin: 0 -6px;
}
.productpanel {
    margin: 0 0 12px 0;
}
.slitemslist p {
    font-size: 22px;
}
.slitemslist span {
    width: 45px;
    height: 45px;
    font-size: 20px;
}
.slitems {
    bottom: 80px;
    right: 70px;
    align-items: center;
    border-radius: 10px;
}
.addproductbtn img {
    margin: 0 0 0 15px;
    width: 18px;
}
.addproductbtn {
    font-size: 15px;
    width: 95px;
    height: 35px;
}
.continuelink {
    font-size: 20px;
    cursor: pointer;
}
.continuelink img {
    width: 12px;
    margin: 0 0 0 12px;
}
.dsproductTabStyle li a {
    font-size: 14px;
    padding:6px 12px !important;
}
.productgrid {
    padding: 0 6px;
}
.details p {
    font-size: 12px !important;
}
.Stlist ul li {
    width: 40px;
    height: 40px;
    font-size: 18px;
}
.dsproductTabStyle li a span {
    padding: 0 0 0 15px;
}
.nav.verticalTabstyle li a {
    font-size: 14px;
    padding: 10px 12px;
}
.productlist p {
    margin: 0 0 20px 0;
    line-height: 23px;

}
.details p {
    margin: 0;
}
.dsleftpanel p span {
    padding: 0 0 0 15px;
}
.dsleftpanel img {
    width: 15px;
}
.paraxl {
    font-size: 24px;
    line-height: 34px;
}
.h1title {
    font-size: 50px;
}
.headermenuline {
    height: 50px;
    margin: 0 15px;
}
.mlist p {
    font-size: 11px;
    left: 22px;
}
.mlist a {
    font-size: 14px !important;
}
.mlist a img {
    width: 18px;
}
.booknow .hr_booknow {
    width: 130px;
    height: 45px;
    font-size: 16px;
    margin: 0 0 0 40px;
}
.sltdroppanel.mlist p {
    left: 5px;
}
.blueoverlay:before {
    width: 500px;
    height: 45px;
}
.banText {
    padding: 12% 0 80px 0;
}
.paralarge {
    font-size: 20px;
    line-height: 30px;
}
.pickServices {
    margin: 50px 0 0 0;
    width: 65vw;
    bottom: 130px;
}
.nav.picktabStyle a {
    font-size: 15px;
    width: 160px;
    height: 60px;
}
.pick_panel p {
    font-size: 12px;
}
.homebannerpanel {
    height: 735px;
    margin:50px 0 0 0;
}
.parrymodal .close {
    font-size: 45px;
}
.popupInner img {
    max-width: 25%;
}
.popuptitle {
    font-size: 22px;
}
.popupInner {
    padding: 5px 70px 50px 70px;
}
.popupsubtitle {
    font-size: 18px;
}
.txtbx {
    padding: 0 20px;
    font-size: 18px;
}
.btn.booknow {
    height: 50px;
    font-size: 15px;
}
.parrymodal .modal-dialog {
    margin: 100px auto;
}
.parasmall {
    font-size: 16px;
    line-height: 26px;
}
.mb50 {
    margin-bottom: 30px;
}
.otpformgroup .otptxt {
    margin: 0 15px 0 0;
}
.otpformgroup {
    padding: 0 55px;
}
.h2subtitle {
    font-size: 26px;
    line-height: 26px;
}
.howitworksCarousel h2 span {
    padding: 0 15px 0 0;
}
.hwrow {
    padding: 50px 40px 70px 100px;
}
.hwright img {
    max-width: 85%;
}
.mb30 {
    margin-bottom: 20px;
}
.hwleft {
    padding: 40px 0 0;
}
.blogcont p {
    font-size: 16px;
    margin:0 0 10px 0;
}
.blogcont span {
    font-size: 20px;
}
.blogcont {
    min-height: 130px;
    padding: 15px 15px;
}
.slickcarouelstyle .slick-dots li {
    height: 10px;
}
.howitworksCarousel .slick-dots li.slick-active {
    width: 10px;
}
.slickcarouelstyle .slick-dots li {
    width: 10px;
}
.h2title {
    font-size: 42px;
    line-height: 52px;
}
.gucontext img {
    width: 180px;
    position: relative;
    left: -40px;
}
#faqTab.TabhomeStyle a {
    font-size: 22px;
}
.tabline {
    margin: 0 60px;
    width: 2px;
    height: 20px;
    margin-top: 6px;
}
.faqaccordion {
    padding: 50px 90px;
}
.faqaccordion button{
    font-size: 18px;
}
.faqaccordion .card-body {
    font-size: 18px;
    padding: 0 0 25px 0;
    line-height: 28px;
}
.faqaccordion .card-header a:after {
    width: 20px;
    height: 20px;
}
.faqInner {
    padding: 80px 0 0 0;
}
.bkgrid img {
    max-width: 460px;
}
.h3title {
    font-size: 28px;
    line-height: 38px;
}
.submitbtn {
    right: 20px;
    top: 10px;
}
.access a img {
    width: 150px;
}
.fTopInner img {
    width: 195px;
}
.fTopInner {
    padding: 50px 0 60px 0;
}
.para {
    font-size: 16px;
    line-height: 26px;
}
.menu_footer li a {
    font-size: 16px;
}
.line {
    width: 1px;
    height: 15px;
    margin: 0 20px;
}
}
@media only screen and (min-width: 1200px) and (max-width:1365px) {
.parrywareHome .container {
    padding: 0 50px;
}
section#process-step-sec.renovation-process h2 {
    font-size: 24px;
    line-height: 32px;
}
.mlist.warrantylogo {
    left: -20px;
}
.mlist.warrantylogo a img {
    max-width: inherit;
}
.banText {
    padding: 12% 0 80px 0;
}
.cleaning-steps h2 img {
    max-width: 50px;
}
.deeplist li {
    font-size: 16px;
    margin: 0 0 5px 0;
}
.ValueaddedPanel .pick_panel img {
    min-height: 85px;
    max-height: 85px;
}
.servicegridCarousel.slickcarouelstyle {
    min-height: 190px;
}
.ValueaddedPanel .pick_panel p {
    min-height: 65px;
}
.cust-name {
    width: 110px;
}
.brandpopup.parrymodal .modal-dialog {
    width: 60%;
    max-width:60%;
}
.ongoingprlist {
    min-height: 70px;
}
.ongoingprlist p {
    font-size: 11px;
    line-height: 16px;
}
.cvlist li p {
    min-height: 50px;
}
.safety-sidebar p {
    font-size: 11px;
    line-height: 18px;
}
.cvlist span {
    font-size: 9px;
}
.cvcontext {
    padding: 15px 15px;
}
.sf-info.para {
    padding: 12px 15px;
    text-align: left;
    font-size: 12px;
}
.cv-title {
    font-size: 16px;
}
.form-group.uploadpanel input {
    width: 60%;
}
.form-group.uploadpanel button {
    width: 60%;
    margin: 20px 0 0 0;
}
.add_list_panel.addonPanel {
    max-width: 100%;
}
.add_product span {
    font-size: 16px;
}
.add_remove_panel span {
    font-size: 16px;
}
.parrywareTable thead, .parrywareTable td {
    font-size: 12px;
}
.gotoproduct .addproductbtn {
    width: 85px;
    height: 30px;
}
.cturl{
    font-size:16px;
}
.cfpformInner p img {
    width: 35px;
}
.nhcontact.chcontact a {
    font-size: 14px;
}
.cfp_info p {
    line-height: inherit;
}
.btn.booknow.ctsubmitbtn {
    width: 180px;
    font-size: 18px;
    height: 60px;
}
.ctformstyle.cttextareastyle {
    height: 70px;
}
.ctformstyle{
    height:40px;
}
.ctform-grid label {
    font-size: 16px;
}
.homebannerpanel.ContactPanel .bgs.homebg {
    height: 50vh;
}
.d-flex.btnoptions{
    display: block !important;
}
.btnoptions a {
    margin: 0 0 10px 0;
}
.topHeader p {
    font-size: 14px;
}
.topHeader {
    height: 50px;
    top: -50px;
}
.homebannerpanel {
    margin: 50px 0 0 0;
    height: 600px;
}
.mlist.dropdown .dropdown-menu {
    min-width: 210px;
}
.dropdown-menu .dropdown-item a {
    font-size: 16px !important;
}
.dropdown-menu .dropdown-item {
    font-size: 16px;
    padding: 15px 20px;
}
.dropdown-menu .dropdown-item span {
    width: 35px;
}
.dropdown-toggle img {
    width: 18px;
}

.gotoproduct .addproductbtn span {
    font-size: 16px !important;
}
.instaright p span {
    font-size: 36px;
}
.instarow .instaleft {
    padding: 0 35px 0 0;
}
.instagrid.instaright .booknow {
    width: 150px;
}
.instarow {
    padding: 25px 55px 25px 40px;
}
.instaright p {
    font-size: 16px;
}
.timelist li.activetime:before {
    width: 25px;
    height: 25px;
}
.dateList li.activedl:before {
    width: 25px;
    height: 25px;
    bottom: -15px;
    left: 30px;
}
.viewdetbx {
    max-height: 90px;
    min-height: 90px;
}
.info-details {
    font-size: 14px;
    padding: 0 0 10px 0;
}
.history-para {
    font-size: 12px;
    padding: 0 10px;
}
.bkgrid {
    padding: 0 10px;
}
.bkdesc p.parasmall {
    font-size: 14px;
}
.bkdate {
    font-size: 10px;
}
.requestbx.viewdetbx img {
    max-width: 35px;
    width: 35px;
}
.view-info .btn.booknow {
    height: 40px;
    font-size: 13px;
}
.requestbx.viewdetbx p {
    font-size: 12px;
}
.info-details {
    font-size: 12px;
}
.orderpanel p.paraxl {
    font-size: 16px;
}
.confirgrid address {
    font-size: 12px;
}
.ratepanel span {
    font-size: 25px;
    right: 40px;
    padding: 4px 0;
}
.rateformpanel .form-group label {
    font-size: 18px;
    padding: 0 0 10px 0;
}
.parrymodal.ratepopup  .modal-dialog {
    width: 50%;
    max-width: 50%;
    margin: 50px auto;
}
.ratepopup .modal-body {
    padding: 0 40px;
}
.btn.btnrequest {
    width: 170px;
    height: 45px;
    font-size: 15px;
}
.bookingrow .bookinggrid.bkleft {
    padding: 35px 20px 15px 20px;
}
.confirmbx {
    font-size: 12px;
}
.cs_inn p {
    font-size: 13px;
}
.paramedium {
    font-size: 16px !important;
}
.dateList li span {
    font-size: 30px;
}
.gonextpanel {
    max-width: 500px;
}
.timelist li {
    width: 170px;
    height: 50px;
    margin: 0 20px 20px 20px;
    font-size: 15px;
}
.dsbx {
    padding: 60px 35px 75px 35px;
}
.dateList li {
    width: 85px;
    height: 85px;
    margin: 0 15px;
    font-size: 14px;
}
.addrForm {
    padding: 40px 45px;
}
.addrinput.addrtxtarea {
    height: 130px;
}

.save_url a {
    width: 125px;
    height: 40px;
    margin: 0 15px 0 0;
    font-size: 14px;
}
.selected_addr .parry_checkbox {
    margin: 0 20px 10px 0;
}  
.btn.newaddressbtn {
    padding: 20px 0;
    font-size: 18px;
}
.selected_addr label .hme {
    font-size: 16px;
    top: 18px;
    left: 50px;
}
.selected_addr p {
    font-size: 11px;
}
.parry_checkbox {
    width: 25px;
    height: 25px;
}
.parry_checkbox:checked:after {
    top: 1px;
    left: 5px;
    font-size: 15px;
}
.add_list_panel {
    max-width: 63%;
}
.addgrid span {
    font-size: 22px;
}
.addonpattern {
    width: 100px;
    height: auto;
    min-width: 100px;
}
.addgrid p {
    padding: 0 0 0 15px;
    font-size: 13px;
}
.descModal .close {
    width: 25px;
    height: 25px;
    font-size: 20px;
}
.mdTitle .prdesc p {
    font-size: 20px;
}
.mdTitle .prdesc span {
    font-size: 14px;
}
.mdcontxt li {
    margin: 0 0 12px 0;
    font-size: 13px;
}
.mdlist p {
    padding: 0 0 15px 0;
}
.prdesc span {
    font-size: 13px;
}
.addproductbtn img {
    vertical-align: middle;
    margin: 0 0 0 15px;
    width: 12px;
}
.addproductbtn {
    font-size: 16px;
    width: 90px;
    height: 30px;
}
.innercontainer {
    padding: 0 25px;
}
.dsleftpanel p span {
    padding: 0 0 0 15px;
}
.details {
    padding: 15px 20px 15px 35px;
}
.continuelink {
    font-size: 20px;
}
.continuelink img {
    width: 11px;
    margin: 0px 0 0 12px;
}
.slitemslist span {
    width: 45px;
    height: 45px;
    font-size: 20px;
}
.slitemslist p {
    font-size: 24px;
    padding: 0 0 0 15px;
}
.slitems {
    bottom: 80px;
    right: 75px;
}
.productpanel {
    margin: 0 0 12px 0;
}

.prCont .details a {
    font-size: 12px;
}
.prCont .details p {
    font-size: 10px;
    margin:0 0 8px 0;
}
.dslogo {
    margin: 0 0 0 55px;
}
.Stlist ul li {
    width: 40px;
    height: 40px;
    font-size: 20px;
}
.Stlist ul li span {
    font-size: 13px;
    bottom: -30px;
}
.dsproductTabStyle li a img {
    width: 60px;
}
.dsproductTabStyle li a{
    font-size: 13px;
    padding: 6px 12px !important;

}
.dsproductTabStyle li a span{
    padding: 0 0 0 10px;
}

.dsRow {
    padding: 25px 0;
}

.bestseller {
    font-size: 13px;
    width: 100px;
    height: 30px;
}
.nav.verticalTabstyle li a {
    font-size: 12px;
    padding: 10px 10px;
}
.verticalTabContent {
    padding: 0 25px;
}
.productRow {
    margin: 0 -6px;
}
.productgrid {
    padding: 0 6px;
}
.productlist p {
    margin: 0 0 15px 0;
}
.productlist {
    margin: 30px 0 0px 0;
}
.dsproductCard.card .verticalTabstyle {

    padding: 40px 25px;
}
.stline {
    width: 115px;
}
.dslogo a {
    width: 80px;
}
.mlist span {
    vertical-align: text-bottom;
    font-size: 12px;
}
.dsleftpanel img {
    width: 14px;
}
.paraxl {
    font-size: 24px;
} 
.booknow .hr_booknow {
    width: 120px;
    height: 40px;
    font-size: 15px;
    margin: 0 0 0 50px;
}
.mlist p {
    font-size: 10px;
    left: 22px;
}
.mlist a {
    font-size: 12px !important;
}
.mlist a img {
    max-width: 15px;
}
.headermenuline {
    width: 1px;
    height: 40px;
    margin: 0 10px;
}
.sltlocation {
    font-size: 12px;
}

.h1title {
    font-size: 40px;
    line-height: 45px !important;
}
.blueoverlay:before {
    height: 40px;
    width: 480px;
}
.paralarge {
    font-size: 16px;
    line-height: 26px;
}
.pickServices {
    margin: 35px 0 0 0;
    bottom: 45px;
    width: 62vw;
}
.nav.picktabStyle a {
    font-size: 13px;
    margin: 0 6px 0 0;
    width: 140px;
    height: 50px;
}
.pick_panel p {
    font-size: 11px;
}
.parrymodal  .close {
    font-size: 45px;
}
.popupInner img {
    max-width: 25%;
}
.popuptitle {
    font-size: 22px;
}
.popupInner {
    padding: 5px 70px 50px 70px;
}
.popupsubtitle {
    font-size: 18px;
}
.txtbx {
    height: 60px;
    padding: 0 20px;
    font-size: 18px;
}
.btn.booknow {
    height: 50px;
    font-size: 16px;
}
.parrymodal .modal-dialog {
    margin: 20px auto;
}
.nhcontact a {
    font-size: 14px;
}
.parasmall {
    font-size: 16px;
    line-height: 26px;
}
.mb50 {
    margin-bottom: 30px;
}
.otpformgroup .otptxt {
    margin: 0 15px 0 0;
}
.otpformgroup {
    padding: 0 40px;
}
.otppanel {
    padding: 0;
}
.hminn img {
    max-height: 60px;
}
.mb30 {
    margin-bottom: 20px;
}
.hwright img {
    max-width: 350px;
}
.hwrow {
    padding: 30px 40px 70px 60px;
}
.h2subtitle {
    font-size: 18px;
    line-height: 28px;
}
.howitworksCarousel .slick-dots li.slick-active {
    width: 10px;
}
.slickcarouelstyle .slick-dots li {
    width: 10px;
    height: 10px;
}
.booknow .btn.booknow {
    width: 150px;
    height: 55px;
    font-size: 16px;
}
.blogcont p {
    font-size: 13px;
    min-height: 55px;
}
.blogcont span {
    font-size: 18px;
}
.blogcont {
    min-height: 130px;
    padding: 15px 15px;
}
.slickcarouelstyle .slick-arrow {
    width: 20px;
    height: 30px;
}
.slickcarouelstyle .slick-prev.slick-arrow {
    right: 50px;
}
.h2title {
    font-size: 24px;
    line-height: 34px;
}
.gucontext img.img-fluid {
    max-width: 30%;
    position: relative;
    left: -40px;
}
#faqTab.TabhomeStyle a {
    font-size: 20px;
}
.tabline {
    margin: 0 50px;
    width: 1px;
    height: 15px;
    margin-top: 6px;
}
.faqaccordion {
    padding: 50px 70px;
}
.faqaccordion button{
    font-size: 18px;
}
.faqaccordion .card-body {
    font-size: 18px;
    padding: 0 0 20px 0;
}
.faqaccordion button:after {
    width: 18px;
    height: 18px;
}
.h3title {
    font-size: 25px;
    line-height: 35px;
}
.bkgrid img {
    max-width: 450px;
}
.access a img {
    width: 120px;
}
.booking_inner {
    padding-top: 20px;
}
.submitbtn {
    right: 15px;
    top: 10px;
}
.fTopInner img {
    max-width: 175px;
}
.para {
    font-size: 13px;
    line-height: 20px;
}
.menu_footer li a {
    font-size: 15px;
}
.line {
    width: 1px;
    height: 15px;
    margin: 0 20px;
}
.mlist.dropdown .dropdown-menu {
    min-width: 165px;
}
.dropdown-menu a {
    padding: 15px 20px;
}

.dropdown-menu a span {
    width: 35px;
}
}
@media only screen and (min-width: 1024px) and (max-width:1199px) {
button#closePromo {
    display: block;
}
.mlist.warrantylogo span {
    display: none;
}
.mlist.warrantylogo p {
    display: none;
}
.mlist.warrantylogo {
    position: relative;
    left: -20px;
    top: -15px;
}
.sltlocation {
    font-size: 16px;
}
.logolist.logolistmobile {
    display: flex !important;
    width: 100%;
    padding:20px 0 20px 0;
}
.topHeader{
    top: 0;
    z-index: 999;
    position: relative;
}
.homebannerpanel {
    margin: 0px 0 0 0;
}
.headerTopInner {
    display: none;
}
.headerTop {
    background: #122C58;
    display: flex;
    position: absolute;
    top: -80px;
    width: 100%;
}
.safety-sidebar {
    width: 35vw;
}
.mlist p {
    font-size: 14px;
}
button#closePromo {
    color: #fff;
    opacity: 1;
    font-weight: normal;
    font-family: 'Roboto-Light';
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 30px;
}
.mlist a {
    font-size: 16px !important;
}
.logolist {
    display: none;
}
.parrywareHome .container {
    padding: 0 30px;
}
.form-group.uploadpanel button {
    width: 60%;
    margin: 20px 0 0 0;
}
.addonlist {
    width: 100%;
}
.d-flex.btnoptions {
    display: block !important;
}
.parrymodal.addrpopup .modal-dialog {
    max-width: 85%;
    width: 85%;
    margin: 100px auto;
}
.form-group.uploadpanel input {
    width: 60%;
}
.tab-content {
    margin-top: 35px !important;
}
.productlist p.paraxl {
    min-height: inherit;
}
.productRow .productgrid {
    flex: 0 0 50%;
    max-width: 50%;
}
.verticalNav {
    width: 30%;
}
#dropdownManual img {
    width: 23px;
}
.mlist span {
    vertical-align: inherit;
    margin: 0 0 0 5px;
    font-size: 16px;
}
.menuPanel {
    width: 75vw;
}
.fTopInner p {
    max-width: 85%;
}
nav.verticalNav {
    width: 35%;
}
.productgrid {
    flex: 0 0 50%;
    max-width: 50%;
}
.productlist p {
    margin: 0 0 30px 0;
}
.TabStyle-products {
    padding: 40px 30px;
}
.instarow .instaleft {
    padding: 0 40px 0 0;
}
.instarow {
    padding: 35px 55px 30px 30px;
}
.servicearea{
    width:85%;
}
.parrymodal.addrpopup .close {
    right: -15px;
    top: -25px;
    width: 40px;
    height: 40px;
    font-size: 30px;
    background: #fff;
    color:#000;
    border-radius: 100%;
    padding:0;
}
.orderpanel {
    max-width: 63%;
}
.history-para {
    padding: 0 10px;
    text-align: center;
}
.viewdetbx {
    max-height: 100px;
    min-height: 100px;
}
.view-info {
    min-height: 200px;
}
.view-info button.btn.booknow {
    width: 100%;
    height: 50px;
    font-size: 16px;
}
.bktabrow .bkgrid {
    flex: 0 0 33.33%;
    max-width: 33%;
}
.dateList li.activedl:before {
    left: 35px;
}
.gonextpanel {
    max-width: 500px;
}
.addrimg img {
    position: static;
}
.addrgrid.addrimg {
    flex: 0 0 100%;
    max-width: 100%;
}
.addrgrid.addrinfo {
    flex: 0 0 100%;
    max-width: 100%;
}
.dateList li {
    width: 100px;
    height: 110px;
    margin: 0 10px;
    font-size: 16px;
}
.add_list_panel {
    max-width: 70%;
    padding: 40px 0 70px 0;
}
.dateList li span {
    font-size: 40px;
}
.dsproductTabStyle li a {
    display: block;
    font-size: 18px;
    padding: 12px 10px !important;
}
.addgrid span {
    font-size: 26px;
}
.dsproductCard.card .verticalTabstyle {
    padding: 40px 30px;
}
.productlist {
    margin: 0 0 50px 0;
}
.verticalTabContent {
    width: 70%;
    padding: 0 40px;
}
.nav.verticalTabstyle li a {
    font-size: 16px;
    padding: 10px 12px;
}
.nav.verticalTabstyle li {
    margin: 0 0 20px 0 !important;
}
.dsproductTabStyle li a span {
    padding: 10px 0;
    display: block;
    text-align: center;
}
.dsproductTabStyle li {
    width: 20%;
    text-align: center;
}
.parrymodal .modal-dialog {
    width: 60%;
    max-width: 60%;
    margin: 50px auto;
}
.slitems {
    bottom: 80px;
    max-width: 45%;
}
.stline {
    width: 100px;
}
.pick_panel p {
    font-size: 13px;
}
.bookingrow .bookinggrid.bkleft {
    padding: 35px 20px 15px 20px;
}
.ratepopup .modal-body {
    padding: 0 40px;
}
.ratepanel span {
    font-size: 26px;
    padding: 2px 5px;
    position: static;
}
.nhpanel p {
    font-size: 20px;
}
.ratingdesc {
    margin: 0 30px 0 0;
}
.btn.btnrequest {
    width: 180px;
    height: 50px;
    font-size: 16px;
}
.innercontainer {
    padding: 0 0px;
}
.confirmimg {
    width: 40px;
}
.paramedium {
    font-size: 20px;
}
.dsleftpanel img {
    width: 15px;
}
.dsleftpanel p span {
    padding: 0 0 0 15px;
}
.paraxl {
    font-size: 26px;
    line-height: 36px;
}
.blueoverlay:before {
    top: 30px;
    left: -30px;
    width: 450px;
    height: 35px;
}
.btn.booknow.fullwidth {
    width: 100%;
}
.otpformgroup {
    padding: 0 10px;
}
.howitworksCarousel .slick-list {
    overflow: hidden;
}
.homebg {
    background-position: right !important;
}
.pickServices {
    bottom: 30px;
}
.banText {
    padding: 0;
    top: 18%;
}
.booknow .hr_booknow {
    width: 150px;
    height: 50px;
    font-size: 18px;
    margin: 0 0 0 40px;
}
.headermenuline {
    width: 1px;
    height: 50px;
    margin: 0 12px;
}
.nav.picktabStyle a {
    font-size: 17px;
    width: 180px;
    text-align: center;
}
.pickServices {
    width: 85vw;
}
.logo {
    width: 15vw;
}
.h1title {
    font-size: 42px;
}
.banTxtInner h1 {
    max-width: 60vw;
    line-height: 65px;
}
.howitworksCarousel h2 span {
    padding: 0 20px 0 0;
}
.btn.booknow {
    width: 200px;
    height: 60px;
    font-size: 22px;;
}
.howitworksCarousel p {
    font-size: 20px;
}
.howitworksCarousel h2 {
    font-size: 26px;
}
.hwrow {
    padding: 50px 0px 50px 50px;
}
.gubg {
    background-position: left  !important;
}
#faq-tabContent {
    margin-bottom: -250px;
}
.booking_inner .bkformgrid {
    padding: 5% 0% 0 0;
}
.ServicesInner {
    padding: 60px 0 60px 0;
}
.ServiceSlickCarousel .slick-dots {
    margin: 60px 0 0 0;
}
.blogcont span {
    font-size: 28px;
}
.slickcarouelstyle .slick-prev.slick-arrow {
    right: 50px;
}
.slickcarouelstyle .slick-arrow {
    width: 20px;
    height: 35px;
}
.blogcont p {
    font-size: 20px;
    margin: 0 0 15px 0;
}
.access {
    padding: 0 0 35px 0;
}
.h2subtitle {
    font-size: 36px;
    line-height: 46px;
}
.booking_inner .bkgrid img {
    width: 85%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.access a img {
    width: 180px;
}
.h3title {
    font-size: 26px;
    line-height: 36px;
}
.faqaccordion .card-body {
    font-size: 20px;
    padding: 0 0 20px 0;
}
.faqaccordion {
    background: #1C2968;
    padding: 20px 40px;
}
.faqaccordion button:after {

    width: 20px;
    height: 20px;
}
.faqaccordion button {
    font-size: 20px;
}
.faqInner {
    padding: 50px 0 0 0;
}
#faqTab.TabhomeStyle a {
    font-size: 24px;
}
.tabline {
    margin: 0 50px;
    width: 1px;
    height: 20px;
    margin-top: 10px;
}
.gupanel {
    height: 75vw;
}
.h2title {
    font-size: 40px;
    line-height: 50px;
}
.gucontext {
    max-width: 75vw;
}
.paralarge {
    font-size: 22px;
    line-height: 32px;
}
.fbtcopyrights {
    flex: 0 0 40%;
    max-width: 40%;
}
.ServicesPanel {
    overflow: hidden;
}
.fTopInner {
    padding: 40px 0 60px 0;
}
.row {
    margin: 0;
}
.fbtmenus {
    flex: 0 0 60%;
    max-width: 60%;
}
.para {
    font-size: 18px;
    line-height: 28px;
}
.menu_footer li a {
    font-size: 16px;
}
.line {
    width: 1px;
    height: 15px;
    margin: 0 10px;
}
}
@media only screen and (min-width: 768px) and (max-width:1023px) {
  .sltlocation {
    font-size: 13px;
}
.mlist.warrantylogo span {
    display: none;
}
.mlist.warrantylogo p {
    display: none;
}
.mlist.warrantylogo {
    left: 10px;
    top: -6px;
}
.mlist.warrantylogo img {
    width: 70px;
}
.cl-text {
    padding: 0;
}   
.valu-form {
    padding: 20px 20px;
}
.clean-panel {
    padding: 20px 15px;
}
.clean-panel p {
    font-size: 18px;
}
.deeplist li {
    font-size: 16px;
    margin: 0 0 5px 0;
}
.deeplistbg {
    height: 35vw;
    background-position: right;
    padding: 20px 10px;
}
.thumbimg {
    max-width: 100%;
}
.clean-process {
    padding: 25px 0!important;
}
.cl-panel-grid {
    display: block;
}
.cleaning-steps h2 {
    padding: 0 0 20px 0;
}
.brandpopup.parrymodal .confirmbx {
    margin: 10px 35px 0 0;
}
button.navbar-toggler {
    display: none;
}
.brandpopup.parrymodal .modal-dialog {
    width: 80%;
    max-width: 80%;
}
.form-group.uploadpanel select {
    width: 180px;
    margin: 5px 10px 0 0;
}
.cust-name {
    width: 100px;
}
.cvlist span {
    font-size: 10px;
}
.cv-title {
    font-size: 18px;
}
.logolist.logolistmobile a img {
    width: 70px;
}
button#closePromo {
    color: #fff;
    opacity: 1;
    font-weight: normal;
    font-family: 'Roboto-Light';
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 30px;
}
button#closePromo {
    display: block;
}
.logolist {
    display: none;
}
.logolist.logolistmobile {
    display: flex !important;
    width: 100%;
    padding:12px 0 12px 0;
}
.topHeader{
    top:-60px;
}
.homebannerpanel {
    margin: 60px 0 0 0;
}
.headerTopInner {
    display: none;
}
.headerTop {
    background: #122C58;
    display: flex;
    position: static;
    top: -60px;
    width: 100%;
}
.safety-sidebar {
    width: 45vw;
    z-index: 999;
}
.parrywareHome .container {
    padding: 0 35px;
}
.DashboardInner.DashboardInvoice .confirgrid {
    padding: 0 30px 0 0 !important;
}
.total.totalconfirmbk {
    width: 100%;
}
.selectbrand .confirmbx input:checked:after {
    top: 2px;
}
.addrForm {
    padding: 50px 45px 30px 45px;
}
.addrinput {
    height: 45px;
}
.parrymodal.addrpopup .modal-dialog {
    max-width: 80%;
    width: 85%;
}
.selected_addr .parry_checkbox {
    margin: 0 20px 0px 0;
}
.add_list_panel.addonPanel {
    margin-bottom: 20px;
}
.nav.verticalTabstyle li {
    margin: 0 0 12px 0 !important;
}
.verticalNav {
    width: 35%;
}
.dropdown-menu .dropdown-item span {
    width: 30px;
}
.dropdown-menu .dropdown-item {
    font-size: 16px;
    padding: 10px 20px;
}
.dropdown-menu .dropdown-item img {
    width: 15px;
}
.dropdown-menu .dropdown-item a {
    font-size: 15px !important;
}
.dropdown-menu .dropdown-item a {
    padding: 0;
}
.mlist span {
    font-size: 13px;
    vertical-align: initial;
}
#dropdownManual img {
    width: 15px;
    margin: 0 5px 0 0;
}
.fTopInner p {
    max-width: 95%;
}
.faqaccordion button:after {
    width: 20px;
    height: 20px;
}
.faqpanel {
    padding: 0 0 50px 0;
}
.faqaccordion button{
    padding:20px 50px 20px 0px;
    font-size: 17px;
}
.gucontext p {
    text-align: justify;
    line-height: initial;
}
.topHeader p {
    font-size: 12px;
}
nav.verticalNav {
    width: 60%;
}
.productlist p {
    margin: 0 0 20px 0;
    min-height: 40px;
    font-size: 14px;
    line-height: inherit;
}
.TabStyle-products {
    padding: 20px 20px;
}
.instarow {
    padding: 40px 55px 30px 40px;
}
.instarow .instaleft {
    padding: 0;
}
.instaright p {
    padding: 0 20px 0 0;
}
  .instagrid.instaright {
    text-align: left;
    padding: 20px 0 0;
    display: flex;
    align-items: center;
}
.servicearea{
    width:85%;
}
.howitworksCarousel .slick-dots {
    bottom: 25%;
}
.dateList li.activedl:before {
    width: 25px;
    height: 25px;
    bottom: -15px;
    left: 25px;
}
.mdcontxt li {
    margin: 0px 0 12px 0;
    font-size: 13px;
}
.descModal .modal-dialog {

    margin: 55px auto 50px auto;
}
.mdTitle .prdesc p {
    font-size: 18px;
}
.viewlink {
    font-size: 14px;
}
.details {
    padding: 15px 15px 15px 20px;
}
.details p {
    font-size: 13px;
    min-height: inherit;
    margin: 0;
}
.submitbtn {
    right: 15px;
    top: 10px;
}
.addproductbtn {
    font-size: 16px;
    height: 35px;
}
.form-booking {
    max-width: 400px;
    margin: 0 auto;
}
.verticalTabContent {
    width: 65%;
    padding: 0 20px;
}
.add_product span {
    font-size: 20px;
}
.productgrid {
    padding: 0 10px;
    flex: 0 0 100%;
    max-width: 100%;
}
.productlist {
    margin: 50px 0 0  0;
}

.nav.verticalTabstyle li a {
    font-size: 13px;
    padding: 10px 15px;
}
.dsproductCard.card .verticalTabstyle {
    padding: 30px 30px;
}
.primg img {
    width: 100%;
}
.dsproductTabStyle li a {
    display: block;
    font-size: 14px;
    padding: 10px 10px !important;
    text-align: center;
}
.dsproductTabStyle li a span {
    padding: 15px 0 0 0;
    display: block;
}
.slitemslist p {
    font-size: 26px;
}
.slitemslist span {
    width: 50px;
    height: 50px;
    font-size: 22px;
}
.continuelink img {
    width: 13px;
    margin: 0 0 0 12px;
}
.continuelink {
    font-size: 20px;
}
.slitems {
    max-width: 50%;
    bottom: 25px;
    padding: 10px 10px;
}
.btn.newaddressbtn {
    padding: 20px 0;
    margin: 40px 0 35px 0;
    font-size: 18px;
}
.newaddressbtn img {
    margin: 0 15px 0 0;
    width: 20px;
}
.selected_addr p {
    font-size: 13px;
}
.selected_addr label .hme {
    font-size: 18px;
}
.view-info {
    min-height: 190px;
}
.addonlist {
    margin: 0 0 5px 0;
    width: 100%;
}
.parry_checkbox {
    width: 30px;
    height: 30px;
}
.parry_checkbox:checked:after {
    left: 8px;
    font-size: 16px;;
}
.addgrid span {
    margin: 0 30px 0 0;
    font-size: 20px;
}
.addgrid p {
    padding: 0 0 0 15px;
    font-size: 15px;
}
.addonpattern {
    width: 100px;
    height: 60px;;
    min-width: 100px;
}
.dsInnerTitle img {
    vertical-align: middle;
}
.confirmimg{
    width:40px;
}
.add_list_panel {
    max-width: 85%;
    padding: 30px 0 0 0;
}
.timelist {
    justify-content: center;
    margin: 0 0 50px 0;
}
.sltime_panel {
    padding: 50px 0 50px 0;
}
.dsbx {
    padding:35px 35px 60px 35px;
}
.save_url {
    margin: 10px 0 40px 0;
}
.timelist li {
    width: 180px;
    height: 55px;
    margin: 0 15px 30px  15px;
    font-size: 16px;
}
.save_url a {
    height: 50px;
    margin: 0 15px 0 0;
    border-radius: 10px;
    font-size: 16px;
}
.gonextpanel.nomargin {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
}
.gonextpanel {
    margin: 0 auto;
    max-width: 400px;
}
.dateList li span {
    font-size: 32px;
}
.dateList li {
    width: 70px;
    height: 90px;
    margin: 0 6px 20px 6px;
    font-size: 12px;
}
.service-check a img {
    margin: 0 0 0 6px;
    width: 9px;
}
a.gonextbtn.fullwidth {
    width: 100%;
}
.stline {
    width: 80px;
    margin: 0 12px;
}
.Stlist ul li {
    width: 40px;
    height: 40px;
    font-size: 18px;
}
.Stlist ul li span {
    font-size: 13px;
}
.fTopInner a img {
    width: 150px;
}
.para {
    font-size: 14px;
    line-height: 24px;
}
.fTopInner {
    padding: 40px 0 50px 0;
}
.parasmall {
    font-size: 14px;
    line-height: 24px;
}
.fbtcopyrights {
    order: 2;
    text-align: center;
    font-size: 20px;
}
.fbtmRow {
    padding: 25px 0;
}
.nav.menu_footer {
    justify-content: center;
    padding: 0 0 20px 0;
}
.menu_footer li a {
    font-size: 14px;
}
.line {
    width: 1px;
    height: 10px;
    margin: 0 20px;
}
.h3title {
    font-size: 22px;
    line-height: 32px;
}
.paralarge {
    font-size: 18px;
    line-height: 28px;
}
.col-lg-6.bkgrid {
    order: 2;
}
.col-lg-6.bkgrid img {
    max-width: 50%;
}
.gucontext {
    position: static;
}
.gupanel {
    height: auto;
}
.gubg {
    position: static;
}
.gucontext img {
    max-width: 30%;
}
.h2title {
    font-size: 28px;
    line-height: 38px;
}
.paralarge {
    font-size: 16px;
    line-height: 26px;
}
.mb30 {
    margin-bottom: 15px;
}
.gucontext {
    max-width: 70vw;
    padding: 20px 0 40px 0;
}
.faqInner {
    padding: 40px 0 0 0;
}
#faqTab.TabhomeStyle a {
    font-size: 16px;
}
.tabline {
    margin: 0 25px;
    width: 1px;
    height: 15px;
    margin-top: 6px;
}
.mb50 {
    margin-bottom: 25px;
}
.TabhomeStyle {
    margin: 0 0 35px 0;
}
.faqaccordion {
    padding: 15px 20px;
}
.faqaccordion .card-header a {
    font-size: 16px;
    font-family: 'Roboto-Regular';
    padding: 25px 0;
}
.faqaccordion .card-body {
    font-size: 14px;
}
.faqaccordion .card-header a:after {
    right: 15px;
    width: 15px;
    height: 15px;
}
.booking_inner .bkformgrid {
    padding: 0;
    text-align: center;
}
.access img {
    width: 130px;
}
.access {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}
#faq-tabContent {
    margin-bottom: -190px;
}
.bkformgrid h2 {
    margin: 0 0 10px 0;
}
.h2subtitle {
    font-size: 30px;
    line-height: 40px;
}
.ServicesInner {
    padding: 55px 0 50px 0;
}
.slickcarouelstyle .slick-arrow {
    width: 20px;
    height: 30px;
    top: -40px;
}
.blogcont p {
    font-size: 18px;
    margin: 0 0 10px 0;
}
.blogcont span {
    font-size: 30px;
}
.blogcont {
    min-height: 140px;
}
.ServicesPanel {
    background: #2E3B7B;
    overflow: hidden;
}
.slickcarouelstyle .slick-prev.slick-arrow {
    right: 50px;
}
.ServiceSlickCarousel .slick-dots {
    margin: 45px 0 0 0;
}
.slickcarouelstyle .slick-dots li {
    width: 10px;
    height: 10px;
    margin: 0 4px 0 0;
}
.hwright {
    order: -1;
    justify-content: center;
    padding: 20px 0;
}
.banText {
    position: static;
}
.hwright img {
    max-width: 45%;
}
.homebg {
    position: static;
}
.homebannerpanel {
    height: auto;
}
.howitworksCarousel .slick-list {
    overflow: hidden;
}
.hrowtitle {
    position: static;
    text-align: center;
    font-size: 24px;
}
.howitworksCarousel .slick-slide {
    float: left;
}
.slickcarouelstyle.slick-slider {
    padding: 0;
}
.hwrow {
    padding: 30px 0;
}
.howitworksCarousel {
    text-align: center;
}
.btn.booknow {
    width: 190px;
    height: 55px;
    font-size: 18px;
}
.btn.booknow.popbtn  {
    width: 100%;
}
.gonextbtn img {
    max-width: 10px;
}
.booknow {
    text-align: center;
}
.howitworksCarousel .slick-dots {
    position: static;
    padding: 0 0 20px 0;
    display: inherit;
}
.howitworksCarousel .slick-dots li.slick-active {
    width: 23px;
    height: 13px;
}
.hmgrid {
    display: flex;
    flex: 0 0 25%;
    max-width: 25%;
    justify-content: center;
}
.hminn img {
    width: 45px;
    max-height: 35px;
}
.banTxtInner h1 {
    line-height: 50px;
}
.h1title {
    font-size: 32px;
}
.blueoverlay:before {
    height: 30px;
    top: 25px;
    width: 360px;
    left: -15px;
}
.pickServices {
    width: 80vw;
}
.nav.picktabStyle a {
    font-size: 11px;
    width: 160px;
    height: auto;
    padding: 20px 0;
}
.row {
    margin: 0;
}
.row.servicesrow {
    overflow: hidden;
}
.servicegridCarousel .slick-dots {
    position: static;
    padding: 0 0 20px 0;
}
.logo {
    width: 14vw;
}
.booknow .hr_booknow {
    width: 115px;
    height: 40px;
    font-size: 14px;
    margin: 0 0 0 30px;
}
.mlist a img {
    top: 0;
    width: 15px;
}
.mlist a {
    font-size: 13px !important;
}
.mlist p {
    font-size: 9px;
    left: 20px;
}
.headermenuline {
    width: 1px;
    height: 45px;
    margin: 0 12px;
}
.homebgmobile {
    background: url(assets/images/home/home-Banner.jpg) no-repeat;
    height: 50vw;
    background-size: cover;
    background-position: center center;
}
.bgs.homebg {
    display: none;
}
header.HeaderPanel {
    background: #1C3868;
}
.menuPanel {
    padding: 0;
    width:75vw;
}
.logo a img {
    width: 90px;
}
.HeaderInner {
    padding:15px 0 10px 0;
}
.mlist.dropdown .dropdown-menu {
    min-width: 160px;
}
.dropdown-menu a {
    padding: 15px 20px;

}
.dropdown-menu a span {
    width: 35px;
}
.homebannerpanel {
    background: #1C3868;
}
.banText {
    position: absolute;
    padding: 0;
    top: 30%;
}
.pickServices {
    width: 90vw;
    position: relative;
    margin: 0;
    bottom: auto;
    z-index: 9;
    padding: 20px 0;
}
.hwleft p.paralarge {
    text-align: center;
    font-size: 18px;
}
.parrymodal .modal-dialog {
    width: 70%;
    max-width: 70%;
    margin: 45px auto;
}
.popuptitle {
    font-size: 20px;
}
.popupInner img {
    width: 80px;
}
.popupsubtitle {
    font-size: 18px;
}
.txtbx {
    height: 60px;
    font-size: 18px;
}
button.btn.booknow.popbtn.fullwidth {
    width: 100%;
    font-size: 20px;
}
.parrymodal  .close {
    font-size: 40px;
}
.pick_panel p {
    font-size: 11px;
}
.otpformgroup {
    padding: 0;
}
.innercontainer {
    padding: 0 0;
}
.paraxl {
    font-size: 20px;
    line-height: 30px;
}
.dsleftpanel img {
    width: 13px;
}
.dsleftpanel p span {
    padding: 0 0 0 10px;
}
.dslogo a {
    width: 80px;
}
.paramedium {
    font-size: 18px;
}
.confirgrid address {
    font-size: 14px;
}
.btn.btnrequest {
    width: 185px;
    height: 50px;
    font-size: 15px;

}
.ratepopup .modal-body {
    padding: 0 45px;
}
.cs_inn p {
    width: 70%;
    font-size: 15px;
}
.nhcontact a {
    font-size: 15px;
}
.ratepanel span {
    font-size: 24px;
    position: static;
    padding: 3px 0;
}
.rateformpanel .form-group label {
    font-size: 16px;
    padding: 0 0 10px 0;
}
.orderpanel img {
    max-width: 110px;
}
.orderpanel p.paraxl {
    font-size: 18px;
}
.orderpanel {
    max-width: 65%;
}
.bktabrow .bkgrid {
    padding: 0 6px;
}
.bookingTab .tab-content {
    padding:40px 20px 40px 20px;
}
.view-info .btn.parrybtn {
    height: 45px;
    font-size: 16px;
}
.history-para {
    font-size: 13px;
    padding: 0 15px;
}
.bkdate span {
    padding: 10px 0;
    display: block;
}
.requestbx.viewdetbx img {
    width: 40px;
}
.bkdesc {
    min-height: 150px;
}
.info-details {
    font-size: 11px;
}
}
@media(min-width:1024px){
.howitworksCarousel.slick-slider .slick-list, .howitworksCarousel.slick-slider .slick-track{
    transform: translate3d(0px, 0px, 0px) !important;
}
}
@media (min-width: 768px) {
.verticalTabstyle.nav-tabs {
    display: flex;
    flex-flow: column nowrap;
  }

  .verticalTabstyle.nav-tabs {
    border-bottom: none;
    display: flex;
  }
  .verticalTabstyle.nav-tabs {
    margin: 0 15px;
  }
  .verticalTabstyle.nav-tabs .nav-item + .nav-item {
    margin-top: 0.25rem;
  }
  .verticalTabstyle.nav-tabs .nav-link {
    white-space: nowrap;
    transition: 0.5s all ease-in-out;
  }
  .verticalTabstyle.nav-tabs .nav-link:hover {
    background-color: #f7f7f7;
    border-color: transparent;
  }
  .verticalTabstyle.nav-tabs .nav-link.active {
    background: #1C3868;
    border: 1px solid #707070;
    color: #fff;
  }
  .verticalTabContent .card {
    border: none;
  }

  .verticalTabContent .card .card-header {
    display: none;
  }

  .verticalTabContent .card .collapse {
    display: block;
  }

}
@media only screen  and (max-width:767px)
{
.cl-text {
    padding: 0;
}   
.deep-btntheme{
    position: static;
}
.mlist.warrantylogo span {
    display: none;
}
.mlist.warrantylogo p {
    display: none;
}
.mlist.warrantylogo img {
    width: 40px !important;
}
.mlist.warrantylogo {
    position: static;
    width: auto !important;
}
.banTxtInner {
    justify-content: center;
}
.logo a img {
    width: 200px;
}
.cleaning-steps h2 img {
    max-width: 50px;
}
.valu-form {
    padding: 20px 20px;
}
.clean-panel {
    padding: 20px 15px;
}
.clean-panel p {
    font-size: 18px;
}
.deeplist li {
    font-size: 16px;
    margin: 0 0 5px 0;
}
.deeplistbg {
    height: 35vw;
    background-position: right;
    padding: 20px 10px;
}
.thumbimg {
    max-width: 100%;
}
.clean-process {
    padding: 25px 0!important;
}
.cl-panel-grid {
    display: block;
}
.cleaning-steps h2 {
    padding: 0 0 20px 0;
}
.combodealbgmobile {
    background: url(assets/images/valueadded/Bathroom-Product-Combo.jpeg) no-repeat;
    width: 100%;
    height: 80vw;
    background-size: cover;
    position: relative;
}
.renovationbgmobile{
    background: url(assets/images/valueadded/Bathroom-Renovation.jpeg) no-repeat;
    width: 100%;
    height: 80vw;
    background-size: cover;
    position: relative;  
}
.wedesignbgmobile{
    background: url(assets/images/valueadded/title-bar-01-bg.jpg) no-repeat;
    width: 100%;
    height: 80vw;
    background-size: cover;
    position: relative; 
}
.ValueaddedPanel .pick_panel p {
    min-height: 60px;
}
.ValueaddedPanel .pick_panel img {
    min-height: 85px;
    max-height: 85px;
}
.form-group.uploadpanel label {
    font-size: 13px;
}
.cust-name {
    width: 60px;
}
.DashboardInner .dslogo {
   float:inherit;
}
.confirmbx input:checked:after {
    top: -3px;
    left: 0px;
}
.confirmbx span {
    font-size: 12px;
}
.brandpopup.parrymodal .confirmbx {
    font-size: 16px;
    margin: 0 6px 6px 6px;
}
.brandpopup.parrymodal .selectbrand .confirmbx input:checked:after {
    top: 5px;
    left: 0;
}
.confirmbx input {
    width: 12px;
    height: 12px;
}
.brandpopup.parrymodal .popupInner {
    padding: 20px 12px 60px 12px;
}
.selectbrand {
    white-space: nowrap;
    flex-wrap: wrap;
}    
.form-group.uploadpanel select {
    width: 46%;
    margin: 0 10px 10px 0;
}
.logolist.logolistmobile {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    border-bottom: 1px solid #f2f2f2;
}   
.logolist.logolistmobile a img {
    width: 50px;
}    
.cv-title {
    font-size: 18px;
}   
.sf-info a img {
    width: 10px;
}
.cvlist span {
    font-size: 11px;
}
.fTopInner p {
    max-width: 100%;
}
.safety-sidebar {
    width: 100%;
}
.logolist {
    display: none;
}
.sltlocation {
    width: 180px;
    margin:0;
    padding: 2px 10px;
    font-size: 12px;
}
.DashboardInner.DashboardInvoice .dsgrid {
    padding: 10px 0;
}
.DashboardInner.DashboardInvoice .dsheaderlist {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
    margin: 0;
}
.DashboardInner.DashboardInvoice {
    padding: 0 0 40px 0;
}
.Contentpanel.invoicecontent {
    padding: 25px 25px;
    margin: 30px 0 0;
}
.parrywareHome .DashboardInner .container {
    padding: 0 15px !important;
}
.parrywareTable thead, .parrywareTable td {
    padding: 6px 10px;
    font-size: 12px;
}
button#closePromo {
    display: block;
}
.dsleftpanel p span {
    padding: 0 0 0 10px;
}
.dsbx.dsbookingpanel .dsInnerTitle p {
    margin: 0;
}
.dsleftpanel img.bookingImg {
    width: 25px;
    position: static;
}
button#closePromo {
    color: #fff;
    opacity: 1;
    font-weight: normal;
    font-family: 'Roboto-Light';
    position: absolute;
    top: -3px;
    right: 6px;
}
.form-group.uploadpanel {
    padding: 0 0px 0 0;
}
.d-flex.btnoptions.mb15 {
    display: block !important;
}
.confirmbx {
    font-size: 14px;
    margin: 0 0 10px 0;
}
.selectbrand .confirmbx input:checked:after {
    top: 0px;
}
.bookingrow .bookinggrid.bkleft {
    padding: 35px 15px 15px 15px;
}
.col-lg-6.col-sm-6.gonextpanel {
    padding: 0;
}
input.btn.booknow.popbtn.fullwidth {
    width: 100%;
}
.parrymodal.addrpopup .modal-dialog {
    max-width: 90%;
    width: 90%;
}
.addrForm .form-group {
    padding: 0;
}
.addonlist {
    width: 100%;
    padding:0;
}
.add_list_panel.addonPanel {
    max-width: 100%;
    padding:20px  0 0px 0;
}
.nav-tabs.dsproductTabStyle{
    padding-bottom: 20px;
}
.productlist p.paraxl{
    min-height: inherit;
}
.dsTab {
    margin: 0 0 10px 0;
}
.dsproductTabStyle {
    margin: 0;
    border: none;
    overflow-y: auto;
    flex-wrap: inherit !important;
}
.servicegridCarousel.slickcarouelstyle {
    padding: 20px 12px;
}
.slick-dotted.slick-slider {
    margin-bottom: 10px !important;
}
.howitworksCarousel>div {
    padding: 0;
}
.faqaccordion .card-body ul {
    margin: 20px 0 0 0;
    padding: 0 0 0 15px;
}
.topHeader p span {
    display: inline-block;
}
.topHeader p {
    font-size: 11px;
}
.homebannerpanel .container {
    padding: 0 !important;
}
.topHeader {
    height: auto;
    top: 0;
    z-index: 9;
}
.gotoproduct .addproductbtn {
    width: 70px;
    height: 30px;
}
.gotoproduct .addproductbtn span {
    font-size: 16px !important;
}
.ctform-grid.ctbtn {
    justify-content: center;
}
div#ngx_captcha_id_1066 div {
    width: 100% !important;
}
div#rc-anchor-container {
    width: 100% !important;;
    box-sizing: border-box;
}
div#ngx_captcha_id_1066 div iframe {
    width: 100% !important;
}
.row{
    margin:0;
}
.btn.booknow.ctsubmitbtn {
    font-size: 18px;
    height: 50px;
}
.ctform-grid label {
    font-size: 15px;
}
.ctformstyle {
    height: 40px;
}
.nhcontact a {
    font-size: 12px;
}
.ctform-grid {
    padding: 0 !important;
    margin:0 0 15px 0;
}
.cfpformInner p img {
    margin: 0 5px 0 0;
    width: 30px;
}
.cfpformInner {
    padding: 20px 20px;
}
.nhcontact.chcontact a {
    font-size: 14px;
}
.cfp_grid.cfp_form {
    margin: 20px 0 0 0;
}
address.para.addrinfo {
    margin: 0px 0 15px 0;
}
.cturl {
    font-size: 16px;
    margin: 20px 0;
}
.cfp_grid.cfp_info {
    padding: 0 0px 0 0 !important;
}
.cfp_row {
    padding: 0 0;
} 
.mlist.dropdown .dropdown-menu img {
    width: 13px;
}
.Top_spacing {
    padding: 100px 0 0 0;
}
.dropdown-menu .dropdown-item {
    font-size: 13px;
    padding: 12px 20px;
}
.dropdown-menu .dropdown-item span {
    width: 30px;
}
.contul li {
    font-size: 12px;
    margin: 10px 0;
}
.Contentpanel h4 {
    font-size: 12px;
    margin: 10px 0;
}
.Contentpanel {
    padding: 25px 0;
}
.h4title {
    font-size: 16px;
    margin: 10px 0;
}
 .bgs.homebg {
    display: none;
}
.slickcarouelstyle .slick-arrow {
    width: 15px;
    height: 25px;
    top:auto;
    bottom: -60px;
}
.slickcarouelstyle .slick-next.slick-arrow {
    right: 40%;
}
.slickcarouelstyle .slick-prev.slick-arrow {
    right: auto;
    left: 45%;
}
.faqInner .TabhomeStyle {
    margin: 0 0 40px 0;
}
.faqpanel {
    padding: 0 0 50px 0;
}
.addproductbtn img {
    margin: 0 0 0 12px;
    width: 15px;
}
.addproductbtn{
    display: flex;
    justify-content: center;
}
.nav.verticalTabstyle li.slick-active a{
    background: #1C3868;
    border: 1px solid #707070;
    color: #fff;
}
.Slickmobilelist.slick-slider .slick-list, .Slickmobilelist.slick-slider .slick-track{
    transform: translate3d(0px, 0px, 0px) !important;
}
.Slickmobilelist .slick-list, .slider-for .slick-list {
    overflow: visible;
}
.dsproductTabStyle li .nav-link.active {
    border-bottom: none !important;
}
.verticalTabContent .collapse {
    display: block;
}
nav.verticalNav {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
}
.productlist p {
    margin: 0 0 20px 0;
    min-height: inherit;
}
.TabStyle-products {
    padding: 20px 20px;
    background: #F2F2F2 !important;
}
.dsTab {
    margin: 0 0 10px 0;
}
.dsproductTabStyle li {
    width: 30%;
    flex: 0 0 30%;
    max-width: 30%;
}
.dsproductTabStyle li a {
    display: block;
    font-size: 10px;
    padding: 10px 10px !important;
}
.dsproductTabStyle li a span {
    padding: 10px 0 0 0;
    text-align: center;
    display: block;
}
.instaright p span {
    font-size: 36px;
}
.instarow .instaleft {
    padding: 0;
}
.instagrid.instaright p {
    padding: 10px 0 0;
}
.instagrid.instaright {
    text-align: center;
}
.slick-dotted.slick-slider {
    margin-bottom: 0px;
}
.instarow {
    padding: 20px;
    border-radius: 0;
}
.instarow .instaleft p.paralarge {
    padding: 0;
    background: transparent;
}
.servicearea.showservicearea p {
    padding: 5px;
}
.servicearea {
    width: 90%;
    margin: 50px 0 0 0;
    right: 20px;
    z-index: 9;
}
.nav.picktabStyle.bookingTabstyle a {
    width: 150px;
    height: 40px;
    font-size: 15px;
}
.bookingTab .tab-content .bkgrid {
    padding: 0;
    margin: 0 0 20px 0;
}
.view-info button.btn.parrybtn.booknow {
    max-width: 100%;
    width: 100%;
}
.bookingTab .tab-content {
    padding:40px 20px;
}
.ratepanel span {
    font-size: 20px;
    right: -5px;
    top: 45px;
}
.dsInnerTitle img {
    width: 40px;
}
.jq-ry-container>.jq-ry-group-wrapper>.jq-ry-group>svg {
    display: inline-block;
    height: 30px;
    margin: 0 13px 0 0 !important;
}
.rateformpanel .form-group label {
    font-size: 16px;
    padding: 0 0 10px 0;
}
.addrinput {
    padding: 10px 10px;
    font-size: 14px;
    height: 45px;
}
.ratepanel p {
    padding: 0 0 12px 0;
}
a.btn.btnrequest {
    max-width: 45%;
    display: block;
    margin: 0 auto !important;
    height: 40px !important;
}
.ratepanel {
    padding: 10px 0;
    position: relative;
}
.ratepopup .modal-body {
    padding: 0 20px;
}
.orderpanel {
    max-width: 100%;
    padding: 45px 20px;
}
.orderpanel p.paraxl {
    font-size: 18px;
}
.orderpanel img {
    max-width: 100px;
}
.btnrequestpanel {
    padding: 20px 0 20px 0;
}
.btnrequestpanel .btn.btnrequest {
    width: 100%;
    margin: 0 0 20px 0;
    height: 50px;
    font-size: 15px;
}
.dsInnerpanel {
    padding: 0px 0 30px 0;
}
.btnrequestpanel.d-flex {
    display: block !important;
}
.form-group.uploadpanel input {
    width: 100%;
}
.service-check a img {
    margin: 0 0 0 15px;
    width: 8px;
}
.cs_inn p {
    font-size: 13px;
}
.gonextpanel.nomargin {
    margin: 0 auto;
    padding: 0 0 0px 0;
}
.cs_inn p.paramedium {
    font-size: 18px;
}
.bookingrow .bookinggrid.bkpayment {
    padding: 35px 15px 15px 15px;
}
.nhcontact {
    justify-content: flex-start;
    width: 100%;
}

.nhpanel p {
    padding: 0 0 15px 0;
}
.nhpanel {
    display: block;
}
.uploadpanel .uploadbtn {
    margin: 20px auto;
    display: block;
}
.bookinggrid.bkleft {
    background: #F3F1F1;
    padding: 35px 25px 15px 20px;
}
.dsbx.dsbookingpanel .dsInnerTitle {
    padding: 0;
    justify-content: center !important;
}
.paramedium {
    font-size: 16px;
}
.total.totalconfirmbk {
    width: 88%;
}
.chooselocation {
    margin: 0;
}
.addrgrid.addrimg {
    padding: 40px 0 0 0;
}
.save_url a {
    width: 125px;
    height: 45px;
    margin: 0 12px 0 0;
    font-size: 14px;
}
.addrForm .form-group p {
    margin: 0 0 12px 0;
    font-family: 'Roboto-Medium';
    font-size: 13px;
}
.addrimg img {
    position: static;
}
.addrForm {
    text-align: left;
    padding: 40px 25px;
}
.newaddressbtn img {
    margin: 0 20px 0 0;
    width: 20px;
}
.gonextbtn img {
    max-width: 10px;
}
.btn.newaddressbtn {
    padding: 12px 0;
    font-size: 18px;
    color: #00A7D5;
    font-family: 'Roboto-Medium';
    margin: 20px 0;
}
.selected_addr label .hme {
    font-size: 18px;
    top: 12px;
    left: 50px;
}
.selected_addr {
    border: 2px dashed #C9C9C9;
}
.dateList li span {
    font-size: 32px;
}
.slickformobile.slickcarouelstyle .slick-dots{
    position: static;
    margin: 50px 0 0 0;
}
.dateList.slickformobile.slickcarouelstyle .slick-dots li.slick-active {
    width: 20px !important;
   height: 10px !important;
    padding: 0 !important;
}
.dsInnerTitle p {
    margin: 20px  0 0;
}
.dateList.slickformobile.slickcarouelstyle .slick-dots li {
    width: 10px !important;
    height: 10px !important;
    padding: 0 !important;
    margin:0 6px;
}
.dateList.slickformobile.slickcarouelstyle .slick-list {
    overflow: visible;
}
.dateList li.activedl:before {
    left: -10px;
    top: -15px;
}
.dateList li {
    width: 80px !important;
    height: 85px;
    font-size: 13px;
    margin: 0 12px 20px 12px;
    padding: 12px 0 !important;
}

.dateList {
    flex-wrap: nowrap;
}
.dltitle span {
    font-size: 17px;
}
.add_li {
    position: relative;
    padding:10px 10px 35px 10px;
}
.add_li .parry_checkbox {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 110px;
    left: auto;
    top: 0px;
}
.gonextpanel {
    max-width: 320px;
    margin:20px auto auto auto;
}
.dsInnerTitle a {
    left: 0;
    top: -25px;
    right: 0;
}
a.gonextbtn.fullwidth {
    width: 100%;
}
.col-sm-8.addgrid {
    align-items: flex-start !important;
}
.col-sm-4.addgrid {
    justify-content: flex-start !important;
    position: absolute;
    margin:0 0 0 30%;
    top: auto;
    left: 0;
    bottom: 5px;
}
.parry_checkbox:checked:after {
    top: 2px;
    left: 5px;
    font-size: 10px;
}
.addgrid span {
    margin: 0 10px 0 0;
    font-size: 18px;
}
.parry_checkbox {
    width: 20px;
    height: 20px;
}
.addgrid p {
    padding: 0 0 0 10px;
    font-size: 12px;
}
.addonpattern {
    width: 70px;
    height: auto;
    min-width: 70px;
}
.descModal .modal-dialog {
    max-width: 90%;
    margin: 50px auto 50px auto;
}
.mdcontxt li {
    margin: 0 0 12px 0;
    font-size: 12px;
}
.mdlist {
    padding: 25px 20px 25px 20px;
}
.descModal .mdTitle {
    padding: 20px 35px;
    display: flex;
    align-items: center;
    flex-flow: wrap;
}
.mdTitle .primg {
    width: 100%;
    background: transparent;
    padding: 0 0 20px 0;
    order: -1;
}
.add_list_panel {
    padding: 0  0 0px 0;
    max-width: 320px;
    overflow: hidden;
}
.mdTitle .prdesc {
    width: 100%;
    background: transparent;
    padding: 0;
}
.dsbx {
    padding: 0px 15px 20px 15px;
    overflow: hidden;
}
a.continuelink {
    font-size: 16px;
}
.slitems {
    bottom: 95px;
    right: 0;
    max-width: 75%;
    padding: 5px 10px;
}
.continuelink img {
    width: 12px;
}
.slitemslist p {
    font-size: 16px;
}
.slitemslist span {
    width: 40px;
    height: 40px;
    font-size: 20px;
}
.d-flex.vtabflex {
    display: block !important;
    width:100%;
}
.dsproductCard.card .verticalTabstyle {
    margin: 0;
    width: 100%;
    padding: 25px 20px;
    background: #E5E5E5;
    display: flex;
    flex-flow: inherit;
    overflow-x: scroll;
    -webkit-overflow-scrolling:touch;
    scroll-behavior: smooth;
    white-space: nowrap    
}
.nav.verticalTabstyle li {
    margin: 0 !important;
    display: flex;
}
.nav.verticalTabstyle li a.active {
    background: #1C3868;
    color: #fff;
    border: none;
    font-family: 'Roboto-Medium';
}
.verticalTabContent {
    width: 100%;
    padding: 20px 20px;
    background: #F2F2F2;
}
.dsproductTabStyle {
    margin: 0;
    border: none;
    overflow-y: auto;
    flex-wrap: inherit;
}
.viewlink {
    font-size: 12px;
    position: absolute;
    right: 20px;
}
.details p {
    font-size: 11px;
    margin:0;
}
.details p:before{
    display: none;
}
.prdesc {
    padding: 10px 10px 10px 15px;
    background: #F2F2F2;
}
.details {
    padding: 15px 20px 10px 20px;
    display: flex;
    background: #ECECEC;
}
.ds-products {
    padding: 20px 0 25px 0;
}
.primg img {
    width: 100%;
}
.productlist {
    margin: 25px  0 0 0;
}
.verticalTabContent .card {
    background: transparent;
    border: none;
    float: none;
    height: auto;
}
.verticalTabContent .slick-track {
    width: 100% !important;
}
.productgrid {
    padding: 0 !important;
}
.verticalTabContent .card-header {
    display: none;
}
.nav.verticalTabstyle li a {
    border:none;
    box-shadow: none;
    background: #fff;
    font-size: 12px;
    color: #1C3868;
    padding: 10px 10px;
    font-family: 'Roboto-Medium';
    min-width: 140px;
    text-align: center;
    margin: 0 10px 0 0;
    align-items: center;
    display: flex;
    justify-content: center;
}
.Stlist ul li span {
    font-size: 9px;
    display: block;
    min-width: 60px;
    top: 35px;
    bottom: auto;
    left: -18px;
}
.Stlist {
    padding: 25px 0 60px 0;
}
.Stlist ul li {
    width: 25px;
    height: 25px;
    font-size: 13px;
}
.stline {
    width: 30px;
    margin: 0 6px;
}
.dslogo a img {
    width: 70px;
}
.dsheaderlist .mlist {
    float: right;
    padding: 0px 20px 0 0;
}
.ongoingprlist {
    min-height: initial;
}
.dslogo {
    margin: 0;
    float: left;
    width: 50%;
}
.dsheaderlist {
    padding: 10px 20px;
    display: list-item;
    width: 100%;
    margin: 0 0 15px 0;
}
.innercontainer {
    padding: 0;
}

.dsgrid.dsleftpanel p {
    justify-content: center;
    font-size: 20px;
    display: flex;
}
.paraxl {
    font-size: 16px;
    line-height: 26px;
}
.DashboardInner .container {
    padding: 0 !important;
}
.dsleftpanel img {
    width: 10px;
    position: absolute;
    left: 20px;
    top: 20px;
}
.dsRow {
    padding: 0;

}
.dsgrid.dsleftpanel {
    order: 2;
    background: #122C58;
    padding: 12px 20px;
}
.parrymodal .modal-dialog {
    width: 100%;
    max-width: 90%;
    text-align: center;
    margin: 35px auto auto auto;
}
.timerstyle {
    font-size: 18px;
    padding: 15px 0 30px 0;
}
.otpformgroup {
    padding:0 15px;
}
.otpformgroup .otptxt {
    margin: 0 20px 0 0;
}
.otppanel {
    padding: 0;
}
button.btn.fullwidth {
    width: 100%;
}
.popupInner {
    padding: 20px 20px 45px 20px;
}
.loginpanel input {
    margin: 15px 0 0px 0;
}
.popuptitle {
    font-size: 18px;
}
.popupsubtitle {
    font-size: 18px;
}
.popupInner img {
    width: 80px;
}
.parrymodal  .close {
    font-size: 35px;
    position: absolute;
    right: 15px;
    top: 0;
}
.headerTopInner {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
}
.headerTopInner .mlist {
    display: flex;
    align-items: center;
    width:40%;
}
.headerTop {
    background: #122C58;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    width: 100%;
}
.mlist.dropdown .dropdown-menu:before {
    content: '';
    position: absolute;
    top: -9px;
    left: 50px;
}
.mlist.dropdown .dropdown-menu {
    min-width: 160px;
    left: -60px !important;
    top: 10px !important;
}
.dropdown-menu a span img {
    position: static;
}
.dropdown-menu a span {
    padding: 0;
    width: 30px;
    top: 0;
    position: static;
}
.mlist .dropdown-menu a {
    padding: 0;
    font-size: 13px !important;
}
.HeaderInner {
    padding: 15px 0;
    align-items: center;
}
.menuPanel {
    width: 74vw;
    padding: 0;
}
.headermenuline {
    display: none;
}
.mlist p {
    font-size: 10px;
    padding: 0;
    left: 23px;
    line-height: initial;
}
.mlist span {

    font-size: 12px;
    vertical-align: inherit;
    font-family: 'Roboto-Medium';
}
#dropdownManual img {
    width: 15px;
    margin: 0 5px 0 0;
}
.booknow .hr_booknow {
    width: 100px;
    height: 35px;
    font-size: 12px;
    margin: 0 0 0 35px;
}
.mlist a {
    font-size: 11px !important;
    font-family: 'Roboto-Medium'!important;
}
.mlist a img {
    top: -6px;
    width: 13px;
}
.logo {
    width: 28vw;
}
.HeaderPanel {
    background: #1C3868;
    top: 60px;
}
.headerTopInner .mlist p {
    left: 0;
    text-transform: inherit;
    padding: 0 5px 0 0;
    display: none;
}
.headerTopInner .mlist {
    padding: 0 10px 0 0;
}
.headerTopInner .mlist img {
    margin: 0 5px 0 0;
    width: 15px;
    top:auto;
}
p.safetyText {
    font-size: 12px;
}
.headerTopInner .mlist  span {
    position: static;
}

.banText {
    position: static;
    padding: 10% 0 0 0;
}
.nav.picktabStyle {
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: center;
    align-items: center;
    overflow-y: scroll;
    white-space: nowrap;
}   
.homebannerpanel .container {
    padding: 0;
}
.pick_panel p {
    padding: 10px 0;
    font-size: 10px !important;
}

.pickServices p.paralarge {
    margin: 0;
    padding: 20px 0;
    text-align: center;
    background: #1C3868;
}
.servicegridCarousel .slick-dots {
    position: static;
    padding: 0 0 20px 0;
}
.servicesrow {
    border-radius: 0;
}
.pickServices p {
    margin: 0;
    padding: 12px 0;
    text-align: center;
}
.homebannerpanel {
    height: auto;
    margin:0;
}
.homebgmobile {
    background: #122C58 url(assets/images/mobile/home-banner.png) no-repeat;
    width: 100%;
    height: 80vw;
    background-size: cover;
    position: relative;
}
 .hrowtitle {
    position: static;
} 
.howitworksCarousel .slick-slide {
    float: left;
}  
.hwright img {
    width: 180px;
    margin: 0 auto;
    padding: 20px 0;
}
.verticalTabstyle .tab-pane {
    display: block !important;
    opacity: 1;
  }
  .ServicesPanel{
      overflow: hidden;

  }
  .TabhomeStyle {
    margin: 0 0 30px 0;
}
  .blueoverlay:before {
    width: 100%;
}  
.row {
    margin: 0 !important;
}
.parrywareHome .container {
    padding: 0 15px;
}
.fTopInner a img {
    width: 150px;
}
.fTopInner {
    padding: 20px 0 35px 0;
}
.para {
    font-size: 13px;
    line-height: 23px;
}
.fTopInner a {
    display: block;
    padding: 0 0 20px 0;
}
.fbtcopyrights {
    order: 2;
    text-align: center;
    padding: 20px 0 0 0;
}
.fbtmRow {
    padding: 20px 0;
}
.nav.menu_footer {
    justify-content: flex-start;
}
.parasmall {
    font-size: 13px;
    line-height: 24px;
}
.menu_footer li a {
    font-size: 10px;
}
.nav.menu_footer {
    justify-content: center;
    text-align: center;
}
.mb20 {
    margin-bottom: 10px;
}
.line {
    width: 0.8px;
    height: 11px;
    margin: 0 6px;
    top: 2px;
    position: relative;
}
.booking_inner .bkformgrid {
    padding: 0;
    text-align: center;
}
.h3title {
    font-size: 16px;
    line-height: 26px;
}
.paralarge {
    font-size: 14px;
    line-height: 22px;
}
.txtbx {
    height: 45px;
    border-radius: 6px;
    padding: 0 15px;
    font-size: 16px;
}
.submitbtn {
    width: 85px;
    font-size: 12px;
    padding: 7px 0;
    right: 15px;
    top: 5px;
}
.access {
    display: flex;
    justify-content: center;
    padding: 15px 0;
}
.access a {
    display: inline-block;
    margin: 0 30px 0 0;
}
.access a img {
    width: 110px;
}
.access a:last-child {
    margin: 0;
}
.bkgrid {
    order: 2;
    margin:0;
}
.bkgrid img {
    max-width: 60%;
}
.h2title {
    font-size: 22px;
    line-height: 32px;
}
.faqInner {
    padding: 30px 0 0 0;
}
.mb50 {
    margin-bottom: 30px;
}
.tabline {
    margin: 0 11px;
    width: 1px;
    height: 12px;
    margin-top: 3px;
}
#faqTab.TabhomeStyle a {
    font-size: 13px;
}
.faqaccordion {
    padding: 15px 15px;
}
.faqaccordion .card-body {
    font-size: 13px;
    padding: 0 0 25px 0px;
}
.faqaccordion button {
    padding:15px 0;
    white-space: initial;
    width: 80%;
    font-size: 15px;
    font-family:'robot-regular';
}
.faqaccordion button:after {
    right: 30px;
    width: 14px;
    height: 14px;
    top: 20px;
}
#faq-tabContent {
    margin-bottom: -190px;
}
.booking_inner {
    padding-top: 20px;
}
.gucontext img {
    max-width: 120px;
}
.gubg {
    position: static;
}
.gupanel {
    height: auto;
}
.gucontext {
    position: static;
    max-width: 100%;
    padding: 50px 0 20px 0;
}
.mb30 {
    margin-bottom: 15px;
}
.ServicesInner {
    padding: 45px 0 30px 0;
}
.ServicesInner h2 {
    text-align: center;
}
.h2subtitle {
    font-size: 22px;
    line-height: 32px;
}
.ServiceSlickCarousel .slick-dots {
    position: static;
    margin: 20px 0 00;
}
.slickcarouelstyle .slick-dots li.slick-active {
    width: 20px;
    background: #00A7D5;
    height: 9px;
}
.slickcarouelstyle.slick-slider {
    padding: 0;
}
.slickcarouelstyle .slick-dots li {
    width: 10px;
    height: 10px;
}
.slickcarouelstyle .slick-slide {
    padding:0 15px 0 0;
}
.blogcont p {
    font-size: 16px;
    margin: 0 0 10px 0;
}
.blogcont span {
    font-size: 16px;
}
.blogcont {
    min-height: 100px;
    padding: 15px 9px;
    border-radius: 0 0 10px 10px;
    margin: -10px 0 0;
}
.blogcont p {
    font-size: 13px;
    margin: 0 0 6px 0;
}
.slickcarouelstyle .slick-list {
    margin: 0;
}
.howitworksCarousel .slick-list {
    overflow: hidden;
}
.hwleft {
    padding: 0;
}
.hwrow {
    padding: 35px 15px 30px 15px;
    text-align: center;
}
.hwright {
    order: -2;
}
.btn.booknow {
    width: 140px;
    height: 45px;
    font-size: 15px;
}
.howitworksCarousel .slick-dots li {
    width: 10px;
    height: 10px;
    margin: 0 3px;
}
.howitworksCarousel .slick-dots li.slick-active {
    height: 10px;
}
.howitworksCarousel .slick-dots {
    display: inherit;
    position: static;
    margin: 30px 0 0 0;
}
.howitworksCarousel h2 span {
    padding: 0 10px 0 0;
    font-size: 18px;
}
.howitworksCarousel .slick-slide p {
    display: block;
    opacity: 1;
}
.howitworksCarousel .slick-slide h2, .howitworksCarousel .slick-slide p {
    opacity: 1;
}
.howitworksCarousel .slick-slide.slick-active p {
    margin: 0 0 0 0;
}
.hminn p {
    font-size: 11px;
    padding: 0 0 0 15px;
    line-height: 22px;
    text-align: left;
}
.banTxtInner h1 {
    max-width: inherit;
    line-height: 40px;
    width: inherit;
    margin: 0 0 20px 0;
}
.h1title {
    font-size: 26px;
}
.pickServices {
    width: 100%;
    position: static;
    margin: 0;
    background: #1C3868;
    padding: 0 0px;
}
.nav.picktabStyle a {
        min-height: 50px;
    font-size: 10px;
    margin: 0px 5px 0 0;
    width: auto;
    height: auto;
    border-radius: 5px 5px 0 0;
    padding: 8px 8px;
}
.blueoverlay:before {
    width: 240px;
    top: 20px;
    left: -10px;
    height: 25px;
}
.homelistpanel .container {
    padding: 0;
}
.hminn img {
    width: 30px;
}
.hmrow {
    padding: 0;
}
.hmgrid {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 15px 15px;
    border-right: 1px dotted #C7CADA;
    border-bottom: 1px dotted #C7CADA;
    align-items: center;
    display: flex;
    border-collapse: separate;
}
.hminn {
    display: flex;
    align-items: center;
}
.hmgrid:nth-child(3), .hmgrid:nth-child(4) {
    border-bottom: none;
}
.hmgrid:nth-child(even) {
    border-right: none;
}
.servicesrow .col-auto {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    margin: 0 0 20px 0;
    padding: 0 5px !important;
}
.slickformobile.slickcarouelstyle {
    overflow: visible;
}
}
@media only screen and (max-width: 340px){
.dsproductTabStyle li a {
    padding: 10px 5px !important;
}
}
